import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import {Helmet} from "react-helmet";
import { useHistory } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Multiselect from 'multiselect-react-dropdown'; // Adjust according to your Multiselect library
import API_URL from './ApiData'
import {useParams} from 'react-router-dom';


const EditVideo = () => {
  const history = useHistory()
  const {editId} = useParams();
  const handleChange = event=>{
    const {name, value} =event.target;
    setInputData({ ...inputData, [name]: value });

  }
  const [cattData, setCattData] = useState([]); // For storing available categories
  const [tagData, setTagData] = useState([]); // For storing selected categories
  const [cssRow, setCssRow] = useState('col-md-3')
  const [cssShort, setCssShort] = useState(true)
  const [readOn, setReadOn] = useState(true)
  const [isVideoRow, setIsVideoRow] = useState(false)
  const [conData, setValue] = useState('');
  const [inputData, setInputData] = useState({
    title: '',
    description: '',
    thumbnail_name: '',
    video_name: '',
    slug: '',
    filename:'',
    preview_name: '',
    is_active: false,
    language: '',
    keywords: '',
    top_video: '',
    tags: [],
    video_duration: '',
    added_by: [],
    editor_name: [],
    uploader_name: localStorage && localStorage.length > 0 && localStorage.userName && localStorage.userName ? localStorage.userName : ''

  })

  const fetchData = async () => {
   
    const allStoryAPI =`${API_URL.VIDEO_EDIT}${editId}/`; 

      axios
      .get(
          allStoryAPI
      )
      .then((res) => {
          if(res && res.data){
            setInputData({
              title: res.data.title,
              description: res.data.description,
              thumbnail_name: res.data.thumbnail_name,
              video_name: res.data.video_name,
              slug: res.data.slug,
              // filename:res.data.filename,
              preview_name: res.data.preview_name,
              is_active: res.data.is_active,
              language: res.data.language,
              keywords: res.data.keywords,
              top_video: res.data.top_video,
              tags_ids: res.data.tags,
              tags: res.data.tags,
              
              filename:  res.data.preview_name.split(".mp4")[0],
              video_duration: res.data.video_duration,
              added_by: res.data.added_by,
              editor_name: res.data.editor_name,
              uploader_name: res.data.uploader_name
            })
            setValue(res.data.description)
            setTagData(res.data.tags)
            if(res.data.video_duration){
              setIsVideoRow(true)
            }
          }
          
      });
  }
  const handleCheckboxChange = (e) => {
    setIsVideoRow(!isVideoRow)

  };

  // Usage
  const fetchCategories = async () => {
    try {
      const response = await axios.get(API_URL.GET_TAGS, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken : ''}`,
        },
      });
      setCattData(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handlePublish = async(e) =>{
    e.preventDefault();
        // Construct the full API payload
        let newTempFilename  = inputData.filename;
        // Creating the formatted file names based on the slug
        const thumbnail_name2 = `${newTempFilename}.jpg`;
        const video_name2 = `${newTempFilename}.mp4`;
        const preview_name2 = `${newTempFilename}.mp4`;
        const video_duration2 = newTempFilename ? `${newTempFilename}-raw.mp4` : null; // Set only if checked

    let datanew = {

      thumbnail_name: thumbnail_name2,
      video_name: video_name2,
      preview_name: preview_name2,
      language: inputData.language,
      keywords:inputData.keywords,
      tags_ids: tagData.map(tag => tag.id),
      video_duration: isVideoRow ? video_duration2 : '',
      uploader_name_id: localStorage && localStorage.length > 0 && localStorage.userId ? localStorage.userId : null,
    }
        try {
          const response = await axios.put(`${API_URL.VIDEO_UPDATE}${editId}/`,datanew , {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken : ''}`,
            },
          });
          if (response.status === 200) {
            console.log('Success:', response.data);
            history.push('/all-videos'); // Redirect to the home page on success
          }
        } catch (error) {
          console.error('Error:', error);
        }
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();

if(!inputData.title){
  alert('Title can not be blank.');
  return false;
}else if(!inputData.description){
  alert('Content can not be blank.');
  return false;
}
const { filename, } = inputData;
// Creating the formatted file names based on the slug
const thumbnail_name = `${filename}.jpg`;
const video_name = `${filename}.mp4`;
const preview_name = `${filename}.mp4`;
const video_duration = filename ? `${filename}-raw.mp4` : null; // Set only if checked

    // Construct the full API payload
let data = {

  title: inputData.title,
  description: conData,
  thumbnail_name: thumbnail_name,
  video_name: video_name,
  preview_name: preview_name,
  language: inputData.language,
  keywords:inputData.keywords,
  tags_ids: tagData.map(tag => tag.id),
  video_duration: isVideoRow ? video_duration : '',
  added_by_id: inputData.added_by && inputData.added_by.id ? inputData.added_by.id : null,
}





    try {
      const response = await axios.put(`${API_URL.VIDEO_UPDATE}${editId}/`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken : ''}`,
        },
      });
      if (response.status === 200) {
        console.log('Success:', response.data);
        history.push('/all-videos'); // Redirect to the home page on success
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    // Fetch tags
    if(localStorage && localStorage.length > 0 && (localStorage.videoOpt || localStorage.editor || localStorage.uploader)){
      setReadOn(false)
    }
    fetchData()
    fetchCategories()
  }, []);
  return (
    <>
<Helmet>
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <link rel="icon" href="icon-new.ico" type="image/x-icon" />
  <link rel="shortcut icon" href="/theme/Ianstv/images/favicon.ico" type="image/x-icon" />
  <meta data-meta-title="Ianstv.in" name="keywords" content="Ianstv news, Ianstv updates, Ianstv celebrities, Hindi cinema, Indian film industry, Ianstv gossips, Ianstv rumors, Ianstv releases, Movie reviews, Celebrity interviews" />
  <meta data-meta-title="Ianstv.in" name="description" content="Stay up-to-date with the latest Ianstv news and updates. Get the hottest gossips, rumors, movie reviews, and exclusive celebrity interviews from the captivating world of Hindi cinema. Your one-stop destination for everything Ianstv." />
</Helmet>
  <div className="wrapper">
  <Header />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-10">
                <h1 className="m-0">Edit Video{inputData.isTagAvailable}</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="col-lg-12">
              <div className="card card-outline card-primary">
                <div className="card-body">
       
                  <form>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="inputHeading">Video Heading: *</label>
                        <input
                          type="text"
                          name="title"
                          className="form-control"
                          id="inputHeading"
                          value={inputData.title}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <label htmlFor="inputSlug">File Name *</label>
                        <input
                          type="text"
                          className="form-control"
                          name="filename"
                          value={inputData.filename}
                          onChange={handleChange}
                          
                        />
                      </div>
                    </div>

                    <div className="d-md-flex">
                        <div className="w-100">
                            <div className="form-group">
                                <label>Main Content *</label>
                                <div className='video-editor'>
                                  <ReactQuill className="form-control form-control-user editShortDesc" 
                                  theme="snow" 
                                  type="text"
                                  name="description" 
                                  value={conData} 
                                  onChange={setValue}
                                  />
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-12">
                        <label htmlFor="inputSlug">Keywords/Hashtags *</label>
                        <textarea
                          type="text"
                          className="form-control"
                          name="keywords"
                          value={inputData.keywords}
                          onChange={handleChange}
                          rows="6"
                        />
                      </div>
                    <div className="form-group">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name="updateVideoDuration"
                          checked={isVideoRow}
                          onChange={handleCheckboxChange}
                        />
                        <label className="form-check-label">RAW Video</label>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="inputLanguage">Language *</label>
                          <select
                            className="form-control"
                            name="language"
                            value={inputData.language}
                            onChange={handleChange}
                          >
                            <option value="">-- Select Language --</option>
                            <option value="English">English</option>
                            <option value="Hindi">Hindi</option>
                          </select>
                      </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="inputCategory">Category *</label>
                          <Multiselect
                            options={cattData}
                            displayValue="slug" // Adjust based on your data structure
                            selectedValues={tagData}
                            onSelect={(selectedList) => setTagData(selectedList)}
                            onRemove={(selectedList) => setTagData(selectedList)}
                            
                          />
                      </div>
                    </div>
                    {  localStorage && localStorage.length > 0 && (localStorage.videoOpt || localStorage.uploader) &&     
                      <button className="btn btn-primary" type="button" onClick={handleSubmit}>Save</button>
                    }                  
                    {  localStorage && localStorage.length > 0 && localStorage.uploader &&     

                      <button className="btn btn-primary" type="button" onClick={handlePublish}>Publish</button>
                    }
                  </form>

                  <div className="video-preview">
                    <div className="container">
                      <div className="row align-items-center">
                        <h4 className="preview-hed">Preview</h4>
                        <div className={cssRow}>
                          <div className="card">
                            <video src={`https://iansportalimages.s3.amazonaws.com/videos_watermark/${inputData.preview_name}`} className="card-img-top" controls></video>
                            <div className="card-body">
                              <h5 className="card-title">Thumbnail Video</h5>
                            </div>
                          </div>
                        </div>
                        <div className={cssRow}>
                          <div className="card">
                          <img className="thumb-image w-100" src={`https://iansportalimages.s3.amazonaws.com/videos_thumbnails/${inputData.thumbnail_name}`}/>
                            <div className="card-body">
                              <h5 className="card-title">Thumbnail Image</h5>
                            </div>
                          </div>
                        </div>
                        <div className={cssRow}>
                          <div className="card">
                            <video src={`https://iansportalimages.s3.amazonaws.com/videos/${inputData.video_name}`} className="card-img-top" controls></video>
                            <div className="card-body">
                              <h5 className="card-title">Edited video</h5>
                            </div>
                          </div>
                        </div>
                        {inputData.tags && inputData.tags.some(tag => tag.id != 7675) && isVideoRow &&
                        <div className={cssRow}>
                          <div className="card">
                            <video src={`https://iansportalimages.s3.amazonaws.com/videos/${inputData.video_duration}`} className="card-img-top" controls></video>
                            <div className="card-body">
                              <h5 className="card-title">RAW Video</h5>
                            </div>
                          </div>
                        </div>
                         }  
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  </>
  );
};

export default EditVideo;
