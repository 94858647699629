import React,{useState, useEffect} from 'react';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import {NavLink} from 'react-router-dom';
import {Helmet} from "react-helmet";
import moment from 'moment';
import API_URL from './ApiData'



const PublishedStory =()=>{
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [userData, setUserData] = useState();
    const [pageList, setPageList] = useState([1,2,3]);
    const [lt, setLt] = useState(5);
    const [lastPage, setLastPage] = useState(10);
    const [publishedObj, setPublishedByUser] = useState();

    const [allStoryList, SetAllStoryData] = useState([]);
    const handleNextPage = () => {
      if(lastPage > currentPage){
        setCurrentPage(currentPage + 1);
        let newArry = pageList;
        let newAddArray = pageList[pageList.length-1]+1;
        // if(currentPage > 2){
          let newRemoveArray = pageList[0];
        //
        // }
        newArry.shift(newRemoveArray);
        newArry.push(newAddArray);
          setPageList(newArry);
      
         }
      
      }
      
      const handlePrevPage = () => {
      if(currentPage > 1){
      
        setCurrentPage(currentPage - 1);
        // setCurrentPage(currentPage + 1);
        let newArry = pageList;
      
        let newAddArray = pageList[pageList.length-1];
        let newRemoveArray = pageList[0]-1;
          newArry.unshift(newRemoveArray);
        newArry.pop(newAddArray);
      setPageList(newArry);
      }
      }
      const handleLastPage = () => {
      //if(requestFlag){
        setCurrentPage(lt);
        let lastArrtty = [lt-3, lt-2, lt-1]
        setPageList(lastArrtty);
      //  }
      }
      const handleStartPage = () => {
      // if(requestFlag){
        setCurrentPage(1);
        let lastArrtty = [1, 2, 3]
        setPageList(lastArrtty);
      //  }
      }
      const handleSetPage = (page) => {
      //  if(requestFlag){
        setCurrentPage(page);
      //}
      }
    const fetchData = async (page , ln) => {
      ln = ln =='ce' ? 'english' :ln; 
      const allStoryAPI = API_URL.Story_URL+`${ln}&partial_status=PUBLISHED&page=${currentPage}`; 
        axios
        .get(
            allStoryAPI
        )
        .then((res) => {
        const allStoryData =
            res.data.data &&
            res.data.data.length > 0 &&
            res.data.data.map((item) => {
            return {
                id: item.id,
                title: item.title,
                current_status: item.current_status,
                slug:'/'+item.slug,
                uuid:item.uuid,
                user_edited_by : item.user_edited_by,
                created_at:item.updated_at,
            };
            });
            if( res.data.data &&
              res.data.data.length > 0){
                //setPublishedByUser(res.data.data.user_edited_by);
              }
        SetAllStoryData(allStoryData);
        });
    }
    const getUserData = (ln)=>{
      const setUserApi =API_URL.getUserData+ln; 

        axios
        .get(
          setUserApi
        )
        .then((res) => {
        const stateD =
            res.data.data &&
            res.data.data.length > 0 &&
            res.data.data.map((item) => {
            return {
                id: item.id,
                groups: item.groups,
                name: item.name,
                email: item.email,
                username: item.username
            };
            });
            setUserData(stateD);
        });      
    }   
    useEffect(() => {
      if(localStorage && localStorage.langg  && (localStorage.langg=='hindi' || localStorage.langg=='ce')){
        // setLangDefault(localStorage.langg);
        fetchData(currentPage, localStorage.langg);
        //getUserData(localStorage.langg);
      }

      }, [currentPage]);
       
    return(
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <link rel="icon" href="icon-new.ico" type="image/x-icon" />
                <link rel="shortcut icon" href="/theme/Ianstv/images/favicon.ico" type="image/x-icon" />
                <meta data-meta-title="Ianstv.in" name="keywords" content="Ianstv news, Ianstv updates, Ianstv celebrities, Hindi cinema, Indian film industry, Ianstv gossips, Ianstv rumors, Ianstv releases, Movie reviews, Celebrity interviews" />
                <meta data-meta-title="Ianstv.in" name="description" content="Stay up-to-date with the latest Ianstv news and updates. Get the hottest gossips, rumors, movie reviews, and exclusive celebrity interviews from the captivating world of Hindi cinema. Your one-stop destination for everything Ianstv." />
            </Helmet>
            <div className="wrapper">
            <Header />
              <div className="content-wrapper">
                <div className="content-header">
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-sm-10">
                        <h1 className="m-0">Published Stories</h1>
                      </div>
                    </div>
                  </div>
                </div>
                <section className="content">
                  <div className="container-fluid">
                    <div className="col-lg-12">
                      <div className="card card-outline card-primary">
                        <div className="card-body">

                          <table className="table tabe-hover table-bordered table-compact" id="">


                            <colgroup dangerouslySetInnerHTML={{
            __html: `
            <col width="5%">
            <col width="32%">
            <col width="15%">
            <col width="15%">
            <col width="15%">
            <col width="15%">
            `
          }}/>
                          
                            <thead>
                              <tr>
                                <th className="text-center">ID</th>
                                <th>Heading</th>
                                <th>Published By</th>
                                <th>Current Status</th>
                                <th>Last Modified</th>
                                <th className='text-center'>Action</th>
                              </tr>
                            </thead>
                            <tbody>		
                              {
                                allStoryList && allStoryList.length > 0 && allStoryList.map((allSty, c)=>{
                                  return(
                                      <tr key = {c}>
                                        <td className="text-center">{allSty.id}</td>
                                        <td>{allSty.title}</td>
                                        <td>{allSty.user_edited_by.username}</td>
                                        <td><span className={allSty.current_status == 'PENDING' ? 'cur-status pending': allSty.current_status == 'CREATED' ? 'cur-status new_mail' : allSty.current_status == 'PUBLISHED' ? 'cur-status published' : 'cur-status being_edited'}>{allSty.current_status}</span></td>
                                        <td>{allSty.created_at}</td>
                                        <td className="text-center">
                                          <div className="btn-group">
                                            <NavLink to={'/stories-edit/'+allSty.uuid+'/'+allSty.id}  className="btn btn-primary btn-flat btn-sm manage_service"><i className="fas fa-edit"></i></NavLink>						  
                                            <NavLink to={'/stories-preview/'+allSty.uuid}   className="btn btn-view btn-flat btn-sm manage_service" target="_blank"><i className="fa fa-eye viewedit" aria-hidden="true"></i></NavLink>
                                          </div>
                                        </td>
                                      </tr>
                                  )
                                })
                              }										
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="pagination">
                      <ul>
                          {currentPage == 1  && <li onClick={handleStartPage} disabled={currentPage === 1}><NavLink className="prevnext disablelink" to="#">Previous</NavLink></li>}


                          {currentPage != 1  &&<li onClick={handlePrevPage} ><a href="#">Previous</a></li>}
                      {pageList.map((data, i)=>{

                          return(
                                                      <li key={i} onClick={()=>{
                          setCurrentPage(data)}}><a href="#" className={currentPage == data ? 'active' : ''}>{data}</a></li>
                                      )
                                  })}

                          <li onClick={handleNextPage}><a href="#" className="prevnext">next</a></li>
                      </ul>                                            							
                    </div>                    
                  </div>
                </section>
                <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Add News</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div className="modal-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="title">Title</label>
                          <input type="text" className="form-control" id="title" placeholder="Enter your title" />
                          <div data-title-error="" className="form-text text-danger error" style={{display:'none'}}>Enter Your Title</div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="shortDesc">Summary</label>
                          <textarea className="form-control form-control-user" placeholder="Summary" rows="6" id="shortDesc" required="" style={{height: '100px'}}></textarea>
                        </div>
                      </div>
                    </div>
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-primary">Save</button>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    </div>
                  </div>
                  </div>
                </div>
      
              </div>
              <Footer />
            </div>
        </>
    )
}

export default PublishedStory