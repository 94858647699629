import React,{useState, useEffect} from 'react';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import {NavLink} from 'react-router-dom';
import {Helmet} from "react-helmet";
import API_URL from './ApiData'

const PendingPictures =()=>{

  const [showElement, setShowElement] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [alertCss, setAlertCss]= useState('')
  const [msg, setMsg] = useState('');
  const [modalData, setModalData] = useState(null);
  const [count, setCount] = useState(0);
  const [reload, setreload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [userData, setUserData] = useState();
  const [pageList, setPageList] = useState([1,2,3]);
  const [lt, setLt] = useState(5);
  const [lastPage, setLastPage] = useState(10);
  const [publishedObj, setPublishedByUser] = useState();
  const [assignUser, setAssignUser] = useState({
    userId :null
  })
  const [allStoryList, SetAllStoryData] = useState([]);
  const [userinfo, setUserInfo] = useState({
    languages: [],
    response: [],
  })
  const handleNextPage = () => {
    if(lastPage > currentPage){
      setCurrentPage(currentPage + 1);
      let newArry = pageList;
      let newAddArray = pageList[pageList.length-1]+1;
      // if(currentPage > 2){
        let newRemoveArray = pageList[0];
      //
      // }
      newArry.shift(newRemoveArray);
      newArry.push(newAddArray);
        setPageList(newArry);
        }
  }
  const handleOnChange = event => {
    const { name, value } = event.target;
    setAssignUser({ ...assignUser, [name]: value });
  }      
     
  const handlePrevPage = () => {
  if(currentPage > 1){
    setCurrentPage(currentPage - 1);
    // setCurrentPage(currentPage + 1);
    let newArry = pageList;
    let newAddArray = pageList[pageList.length-1];
    let newRemoveArray = pageList[0]-1;
      newArry.unshift(newRemoveArray);
    newArry.pop(newAddArray);
  setPageList(newArry);
  }
  }
  const handleLastPage = () => {
  //if(requestFlag){
    setCurrentPage(lt);
    let lastArrtty = [lt-3, lt-2, lt-1]
    setPageList(lastArrtty);
  //  }
  }
  const handleStartPage = () => {
  // if(requestFlag){
    setCurrentPage(1);
    let lastArrtty = [1, 2, 3]
    setPageList(lastArrtty);
  //  }
  }
  const handleSetPage = (page) => {
  //  if(requestFlag){
    setCurrentPage(page);
  //}
  }
  const getPageById=(page)=>{
    if(page <= lastPage){
      setCurrentPage(page)

    }
  }
  const handleOpenModal = async () => {
    try {
      let ln = localStorage && localStorage.langg ? localStorage.langg : ''; 
      ln = ln =='ce' ? 'english' :ln; 

      const response = await axios.get(API_URL.getStoryCount+ln);
      setModalData(response.data);
      setShowModal(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setModalData(null); // Reset modal data when closing
  };
  const fetchData = async (page, ln) => {
    if(currentPage <= lastPage){
    ln = ln =='ce' ? 'english' :ln; 
    const allStoryAPI =`https://cms.iansnews.in/api/list-image-stories/?partial_status=Pending&page=${currentPage}`; 
      axios
      .get(
          allStoryAPI
      )
      .then((res) => {
      const allStoryData =
      res.data.data &&
      res.data.data.length > 0 &&
      res.data.data.map((item) => {
          return {
              id: item.id,
              title: item.title,
              current_status: item.current_status,
              slug:'/'+item.slug,
              images:item.images && item.images.length > 0 && item.images[0].image ? item.images[0].image : '',
              uuid:item.uuid,
              user_edited_by : item.user_edited_by,
              created_at:item.created_at,
          };
          });
          if( res.data &&
            res.data.num_pages){
              setLastPage(res.data.num_pages)
              //setPublishedByUser(res.data.data.user_edited_by);
            }
      SetAllStoryData(allStoryData);
      console.log(allStoryData, 'tesss')
      });
    }
  }
 
    useEffect(() => {
      setTimeout(function () {
              if (modalData) {
          const totalCount = modalData['today\'s Count'];
          setCount(totalCount);
        }
        setTimeout(function() {
          setShowElement(false);
          }, 3000);
          if(localStorage && localStorage.langg && localStorage.langg=='mt'){
        fetchData(currentPage, localStorage.langg);
      }
    }, 800);
      }, [currentPage, modalData, msg]);
    return(
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <link rel="icon" href="icon-new.ico" type="image/x-icon" />
                <link rel="shortcut icon" href="/theme/Ianstv/images/favicon.ico" type="image/x-icon" />
                <meta data-meta-title="Ianstv.in" name="keywords" content="Ianstv news, Ianstv updates, Ianstv celebrities, Hindi cinema, Indian film industry, Ianstv gossips, Ianstv rumors, Ianstv releases, Movie reviews, Celebrity interviews" />
                <meta data-meta-title="Ianstv.in" name="description" content="Stay up-to-date with the latest Ianstv news and updates. Get the hottest gossips, rumors, movie reviews, and exclusive celebrity interviews from the captivating world of Hindi cinema. Your one-stop destination for everything Ianstv." />
            </Helmet>
            <div className="wrapper">
              <Header />
                <div className="content-wrapper">
                  <div className="content-header">
                    <div className="container-fluid">
                      <div className="row mb-2">
                        <div className="col-sm-10">
                          <h1 className="m-0">All Pictures</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <section className="content">
                    <div className="container-fluid">
                      <div className="col-lg-12">
                        <div className="card card-outline card-primary">
                          <div className="card-body">
                            <ul className="pending-pic">
                                { 
                                allStoryList && allStoryList.length > 0 && allStoryList.map((allPicture, a)=>{
                                let imggg = allPicture.images.replace("/images/", "/thumbnails_watermark/")
                                  return(
                                    <li key={a}>
                                      <img src={imggg} />
                                      <div className="pending-icon">
                                        <NavLink to="#"><i className="fa fa-eye" aria-hidden="true"></i></NavLink> 
                                        <NavLink to={'/edit-pictures/'+allPicture.uuid}><i className="fa fa-edit" aria-hidden="true"></i></NavLink>                                
                                      </div>
                                      <p>{allPicture.title}</p>
                                    </li>
                                  )
                                })
                                }
                            </ul>
                            <div className="pagination">
                            <ul>
                          {currentPage == 1  && <li onClick={handleStartPage} disabled={currentPage === 1}><NavLink className="prevnext disablelink" to="#">Previous</NavLink></li>}
                          {currentPage != 1  &&<li onClick={handlePrevPage} ><a href="#">Previous</a></li>}
                          {pageList.map((data, i)=>{
                          return(
                                <li key={i} onClick={()=>{
                                  getPageById(data)}}><a href="#" className={currentPage == data ? 'active' : ''}>{data}</a></li>
                                      )
                                  })}
                          <li onClick={handleNextPage}><a href="#" className="prevnext">Next</a></li>
                      </ul>   
                            </div>
                          </div>
                        </div>						
                      </div>        
                    </div>
                  </section>
                </div>
              <Footer />
            </div>
        </>
    )
}
export default PendingPictures