import React,{useState, useEffect} from 'react';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import {useParams} from 'react-router-dom';
import { Multiselect } from "multiselect-react-dropdown";
import "./style2.css";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom';
import API_URL from './ApiData'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const StoryEdit =()=>{
    const [isLoading, setIsLoading] = useState(false);
    const [imgModal, setImgModal] = useState(false);
    const [webData, setWebsiteData] = useState();
    const [msg, setMsg]= useState('')
    const [alertCss, setAlertCss]= useState('')
    const [tagData, setTagData] = useState();
    const [stateData, setStateData] = useState();
    const [cattData, setCategoryData] = useState();
    const [uploadImg, setUploadImage] =useState({
      'title':'',
      'keywords':'',
      'caption':'',
    })

  const [selectedOptions, setSelectedOptions] = useState();
  const [selectedStateOptions, setSelectedStateOptions] = useState();
  const handleOnMultiSelectChange = (event) => {
    const selectedValues = Array.from(event.target.selectedOptions, option => option.value);
    setSelectedOptions(selectedValues);
  };
  const [conData, setValue] = useState('');

  const handleOnStateMultiSelectChange = (event) => {
    const selectedStateValues = Array.from(event.target.selectedOptions, option => option.value);
    setSelectedStateOptions(selectedStateValues);
  };

    const style = {
      chips: {
        background: "red"
      },
      searchBox: {
        border: "none",
        borderBottom: "1px solid blue",
        borderRadius: "0px"
      },
      multiselectContainer: {
        color: "red"
      }
    };
  
  // Create a function to count words
  function countWords(text) {
    const words = text.trim().split(/\s+/);
    return words.filter((word) => word !== '').length;
  }



  const wordCount = countWords(conData)
    const handleFileChange = (e) => {
      if(e && e.target && e.target.files[0]){
        if(e.target.files[0].type != "image/jpeg" && e.target.files[0].type != "image/jpg"){
          setMsg(`${e.target.files[0].type}  not be allowed.`)
          setAlertCss('alert-error')  
          setShowElement(true)   
          e.target.value = null;

        }
      }
      const files = e.target.files[0];
      setSelectedFile(files)
      setSelectedFiles(files); // Use the spread operator to create a new array
    };
    
    const [userinfo, setUserInfo] = useState({
      languages: [],
      response: [],
    });
    const [searchImg, setSearchImg] = useState({
      name:''
    });
    const [imgSearchD, setSearchImgAry] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [imgArry, setImgAry] = useState([]);
    const [upneImg, setUpImgAry] = useState([])
    const [pageList, setPageList] = useState([1,2,3]);
    const [lt, setLt] = useState(5);
    const {editId, storyId} = useParams();
    const [isChecked, setIsChecked] = useState(false);
    const history = useHistory();

    const handleChecked = (event) => {
      setIsChecked(event.target.checked);
    };
    const[inputData, setInputData] = useState({
      byline:'IANS',
      title: '',
      short_desc:  '',
      content: '',
      tags: '',
      topNews_category: '',
      image_caption:  '',
      top_story: false,
      keywords:  '',
      websites: '',
      states: '',
      images: null,
      slug: ''
  })
    const handleChange = (e) => {
      // Destructuring
      const { value, checked } = e.target;
      const { languages } = userinfo;
        
      console.log(`${value} is ${checked}`);
       
      // Case 1 : The user checks the box
      if (checked) {
        setUserInfo({
          languages: [...languages, value],
          response: [...languages, value],
        });

         
        let newArry = imgSearchD.filter(item => item.id === parseInt(value));
        // let newUpady = imgArry.unshift(newArry[0]);
        // setImgAry(newArry[0], ...newUpady)
        setImgAry(imgArry => [newArry[0], ...imgArry]);
        //imgArry
      }
    
      // Case 2  : The user unchecks the box
      else {
        setUserInfo({
          languages: languages.filter((e) => e !== value),
          response: languages.filter((e) => e !== value),
        });
        // const filteredSrImages = upneImg.filter((e) => e !== value);
        // setUpImgAry(filteredSrImages);
        let newArry = imgArry.filter(item => item.id != parseInt(value));
        setImgAry(newArry);
      }
     // const filteredSImages = userinfo.response.filter(item => item.id === value);
      //setUpImgAry(filteredSImages);
      console.log('IANSNN', imgArry)
    };    

  const [show, setShow] = useState(false);
  const [mainCate, setMainCategory] = useState([
    {
        "id": 4,
        "slug": "business",
        "name": "Business",
        "hindi_name": "व्यापार"
    },
    {
        "id": 8,
        "slug": "entertainment",
        "name": "Entertainment",
        "hindi_name": "मनोरंजन"
    },
    {
        "id": 11,
        "slug": "health-medicine",
        "name": "HEALTH/MEDICINE",
        "hindi_name": "स्वास्थ्य/चिकित्सा"
    },
    {
        "id": 3,
        "slug": "international",
        "name": "International",
        "hindi_name": "अंतरराष्ट्रीय"
    },
    {
        "id": 1,
        "slug": "national",
        "name": "National",
        "hindi_name": "राष्ट्रीय"
    },
    {
        "id": 6,
        "slug": "science-tech",
        "name": "Science/Tech",
        "hindi_name": "विज्ञान/प्रौद्योगिकी"
    },
    {
        "id": 5,
        "slug": "sports",
        "name": "Sports",
        "hindi_name": "खेल"
    }
    
])
  const updateImgArry = ()=>{
    console.log('ar',userinfo);
  }
  const [realData, setRealtedData] = useState()
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [realtedStory, setRealtedStory] = useState('');
  const [selectedTags, setSelectedTags] = useState(['raju']);

  const handleTagChange = newTags => {
    setSelectedTags(newTags);
  };
    const [lastPage, setLastPage] = useState(10);
    const handleOnMuliSelectChange = event => {
      const selectedOptions = event.target.selectedOptions;
      const selectedValues = Array.from(selectedOptions, option => option.value);
      if(event.target.name && event.target.name == 'states'){
        setInputData({ ...inputData, states: selectedValues });
      }else if(event.target.name && event.target.name == 'websites'){
        setInputData({ ...inputData, websites: selectedValues });
      }
    };
    const handleOnChange = event => {
      const { name, value } = event.target;
      setInputData({ ...inputData, [name]: value });
    };
    const handleUpload = event =>{
      const { name, value } = event.target;
      setUploadImage({ ...uploadImg, [name]: value });
    }
    const handleOnImgChange = event =>{
      const { name, value } = event.target;

      setSearchImage({ ...searchImg, [name]: value })
    }
    const [currentImgPage, setImgCurrentPage] = useState(1);
    const [searchImage, setSearchImage] = useState('');
    const [allStoryList, SetAllStoryData] = useState([]);
    const handleImgNextPage = (curr) => {
      //if(lastPage > currentPage){
        setImgCurrentPage(curr);
        //if(currentImgPage > 1){
          getSearchImg(curr);
      }
    const imgSetPage =(curr)=>{
      setImgCurrentPage(curr);
      //if(currentImgPage > 1){
        getSearchImg(curr);
    }
    const handleNextPage = () => {
      if(lastPage > currentPage){
        setCurrentPage(currentPage + 1);
        let newArry = pageList;
        let newAddArray = pageList[pageList.length-1]+1;
        // if(currentPage > 2){
          let newRemoveArray = pageList[0];
        //
        // }
        newArry.shift(newRemoveArray);
        newArry.push(newAddArray);
          setPageList(newArry);
      
         }
      }
      const [selectedFile, setSelectedFile] = useState(null);


    const getSearchImg = (page)=>{
     // event.preventDefault();
     if(page && page == 1){
      setImgCurrentPage(1);
     }

      if(searchImage.name){
        setShow(true);
        const imSearchAPI = `https://cms.iansnews.in/api/elastic/image/search/?query=${searchImage.name}&page=${page}`;
        axios.get(
          imSearchAPI,
          {
            headers: {
              Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
            },
          }
        ).then((res) => {
        const allImgData =
            res.data.data &&
            res.data.data.length > 0 &&
            res.data.data.map((item) => {
            return {
                id: parseInt(item.id),
                title: item.title,
                image: item.thumbnail,
                caption: item.caption,
                keywords:item.keywords 
            };
            });
        if(res && res.data && res.data.data && res.data.data.length > 0 ){
          setSearchImgAry(allImgData);
          console.log('vbb',imgSearchD);
        }

        //console.log('TMT-varun', res);
        });
      }
    
    
    }      
    const handlePrevPage = () => {
    if(currentPage > 1){
    
      setCurrentPage(currentPage - 1);
      // setCurrentPage(currentPage + 1);
      let newArry = pageList;
    
      let newAddArray = pageList[pageList.length-1];
      let newRemoveArray = pageList[0]-1;
        newArry.unshift(newRemoveArray);
      newArry.pop(newAddArray);
    setPageList(newArry);
    }
    }
    const handleImgPrevPage = (curr)=>{
      if(curr > 0){
        setImgCurrentPage(curr);
        getSearchImg(curr);
      }
    }
    const handleLastPage = () => {
    //if(requestFlag){
      setCurrentPage(lt);
      let lastArrtty = [lt-3, lt-2, lt-1]
      setPageList(lastArrtty);
    //  }
    }
    const removeImage =(id)=>{
      const filteredImages = imgArry.filter(item => item.id != id);

      //imgArry.filter((filterImg, f)==)
      setImgAry(filteredImages);
      //alert(id)
    }
    const handleStartPage = () => {
    // if(requestFlag){
      setCurrentPage(1);
      let lastArrtty = [1, 2, 3]
      setPageList(lastArrtty);
    //  }
    }
    const handleSetPage = (page) => {
    //  if(requestFlag){
      setCurrentPage(page);
    //}
    }
    const fetchData = async (page, ln) => {
      ln = ln =='ce' ? 'english' :ln;
      
      const allStoryAPI =`https://cms.iansnews.in/api/single-story-data/?uuid=${editId}`; 
    
        axios
        .get(
            allStoryAPI
        )
        .then((res) => {
        if(res && res.data && res.data.data){
          setInputData({
            byline:res.data.data.byline ? res.data.data.byline :'IANS',
            title:res.data.data.title ? res.data.data.title :'',
            short_desc:  res.data.data.short_desc ? res.data.data.short_desc :'',
            content: res.data.data.content ? res.data.data.content :'',
            tags: '',
            topNews_category: res.data.data.topNews_category && res.data.data.topNews_category.slug ?res.data.data.topNews_category.slug : '',
            image_caption:  res.data.data.image_caption ? res.data.data.image_caption :'',
            top_story: res.data.data.top_story,
            keywords:  res.data.data.keywords ? res.data.data.keywords :'',
            summary:  res.data.data.summary ? res.data.data.summary :'',

            websites: '',
            states: '',
            images: null,
            slug: res.data.data.slug ? res.data.data.slug :''
          })
          setValue(res.data.data.content ? res.data.data.content :'')
          setIsChecked(res.data.data.top_story);
        }
        if(res.data.data.current_status == 'CREATED'){
          mailSendData(res.data.data)
        }
       
        if(res && res.data && res.data.data && res.data.data.images && res.data.data.images.length > 0 ){
          setImgAry(res.data.data.images);
        }
        
        if(res && res.data && res.data.data && res.data.data.tags){
          
          let t12 = res.data.data.tags.split(",");
          const net = t12 && t12.length > 0 && t12.map((tt, t)=>{
            return {
              id: t,
              slug: tt,
              name: tt

            }
          }) 

          setTagData(net);
        }
        if(res && res.data && res.data.data && res.data.data.websites){

          //let tww12 = res.data.data.websites.split(",");
          let stringArray = res.data.data.websites.length > 0 && res.data.data.websites.map(item => item.name);
          setSelectedOptions(stringArray);
        } 
        if(res && res.data && res.data.data && res.data.data.states){
          // let twwv12 = res.data.states.split(",");
          let twwv12 = res.data.data.states.length > 0 && res.data.data.states.map(item => item.id);
          setSelectedStateOptions(twwv12);
        }
              
        SetAllStoryData(res.data.data);
        if(res.data.data.related_story){
          setRealtedStory(res.data.data.related_story);
          // getRealtedStoryData(res.data.data.related_story);
        }
        //console.log('TMT-varun', res);
        });

    }
    const getRealtedStoryData = (realId)=>{
      const getWebApi =`https://cms.iansnews.in/api/list-all-stories/?story_id=${realId}`; 
        axios
        .get(
          getWebApi
        )
        .then((res) => {
          if(res && res.data && res.data.data && res.data.data.length > 0){
            setRealtedData(res.data.data[0]);
          }
        });      
    }
    const getWebSiteData = ()=>{
      const getWebApi =`https://cms.iansnews.in/api/websites/list/`; 

        axios
        .get(
          getWebApi
        )
        .then((res) => {
        const webD =
            res.data.data &&
            res.data.data.length > 0 &&
            res.data.data.map((item) => {
            return {
                id: item.id,
                description: item.description,
                name: item.name
            };
            });
        setWebsiteData(webD);
        });      
    }
    const getStateData = ()=>{
      const getStateApi =`https://cms.iansnews.in/api/state/list/`; 

        axios
        .get(
          getStateApi
        )
        .then((res) => {
        const stateD =
            res.data.data &&
            res.data.data.length > 0 &&
            res.data.data.map((item) => {
            return {
                id: item.id,
                code: item.code,
                name: item.name
            };
            });
            setStateData(stateD);
        });      
    }  
    const getCategoryData = ()=>{
        axios.get(API_URL.getTagData).then((res) => {
        const tagsD =
            res.data &&
            res.data.length > 0 &&
            res.data.map((item) => {
            return {
                id: item.id,
                slug: item.slug,
                name: item.name
            };
            });
            setCategoryData(tagsD);
        });      
    }
    const handlePaste = () => {
      console.log(conData, 'vikas')
      //e.preventDefault();
      const text = conData;
      // Apply your cleanup logic here (e.g., removing unwanted characters or tags)
      // For simplicity, let's assume you want to strip HTML tags from the pasted content:
      let  cleanText = text.replace('<p>', 'vik2')
      cleanText = text.replace('</p>', 'vik3')
       cleanText = text.replace(/<[^>]*(>|$)/g, '');
       cleanText = text.replace('vik2', '<p>')
       cleanText = text.replace('vik3', '</p>')
      // Insert the cleaned text into the editor
      //const newContent = `${cleanText}`;
      setValue(cleanText);
    };
    const SaveStoryData = ()=>{
      
      //const tggg = selectedList;
      setMsg('')
      //console.log(tagData, 'varun');
      setIsLoading(true);
     if(!inputData.topNews_category){
      setMsg('Main category can not be blank.')
      setAlertCss('alert-error')  
      setShowElement(true)  
      return false; 
    }
      let nameArray = tagData && tagData.length > 0 && tagData.map(item => item.slug); // Extract 'name' property from each object
  
      let resultString = nameArray && nameArray.join(',').replace(/\s/g, '');
      let cleanText = conData.replace(/&nbsp;/g, ' ');
        cleanText = `${cleanText}`.replace(/<(?!\/?(p|img)\b)[^>]+>/g, (match) => {
        if (match.match(/<img\b/)) {
          return match.replace('>', ' />');
        } else {
          return '';
        }
      });
      
      // const newtext= cleanText.replace('<p>&nbsp;</p>', '')
      // let newContent = cleanText.replace('img_001L', `<img src='${imgArry[0].image.replace('thumbnails', 'images')}' />`);
      // newContent = newContent.replace('img_002L', `<img src='${imgArry[1].image.replace('thumbnails', 'images')}' />`);
      // newContent = newContent.replace('img_003L', `<img src='${imgArry[2].image.replace('thumbnails', 'images')}' />`);
      // newContent = newContent.replace('img_004L', `<img src='${imgArry[3].image.replace('thumbnails', 'images')}' />`);
      // newContent = newContent.replace('img_005L', `<img src='${imgArry[4].image.replace('thumbnails', 'images')}' />`);
      // newContent = newContent.replace('img_006L', `<img src='${imgArry[5].image.replace('thumbnails', 'images')}' />`);
      // newContent = newContent.replace('img_007L', `<img src='${imgArry[6].image.replace('thumbnails', 'images')}' />`);
      // newContent = newContent.replace('img_008L', `<img src='${imgArry[7].image.replace('thumbnails', 'images')}' />`);
      // newContent = newContent.replace('img_009L', `<img src='${imgArry[8].image.replace('thumbnails', 'images')}' />`);
      // newContent = newContent.replace('img_010L ', `<img src='${imgArry[9].image.replace('thumbnails', 'images')}' />`);
     
      let newContent = cleanText;

      for (let i = 0; i < 10; i++) {
        if(imgArry && imgArry[i] && imgArry[i].image) {
          const imgPlaceholder = `img_00${i + 1}L`;
          const imgSrc = imgArry[i].image.replace('thumbnails', 'images');
          const imgTag = `<img src='${imgSrc}' />`;
          newContent = newContent.replace(imgPlaceholder, imgTag);
        }
      }
       
      newContent = `${newContent}`;
      
      console.log('imgTest',imgArry);
        var formData = new FormData();
        let imgArray = imgArry && imgArry.length > 0 && imgArry.map(item => item.id); // Extract 'name' property from each object
        let resultImgString = imgArray && imgArray.join(', ');
        formData.append('byline', inputData.byline);
        formData.append('title', inputData.title);
        formData.append('short_desc', inputData.short_desc);
        formData.append('content', `${newContent}`);
        formData.append('tags', inputData.tags);
        formData.append('topNews_category', inputData.topNews_category);
        formData.append('image_caption', inputData.image_caption);
        formData.append('top_story', isChecked);
        formData.append('keywords', inputData.keywords);
        formData.append('websites', selectedOptions);
        formData.append('states', selectedStateOptions);
        formData.append('images', resultImgString);
        formData.append('slug', inputData.slug);
        formData.append('tags', resultString);
  console.log(formData, 'tesettt')
  const getStateApi =`https://cms.iansnews.in/api/stories/${editId}/edit/`; 
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
  //   },
  // };
  axios({
    method: "post",
    url: getStateApi,
    data: formData,
    headers: { 
      "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
  
  },
    
  }).then((res) => {
    if(res && res.data && res.data.data && res.data.data == 'Success' && res.data.message){
      //alert(res.data.message)
      setMsg(res.data.message)
      setAlertCss('alert-success')
      setShowElement(true)
    }
    console.log(res,'cccccccccc');
    setIsLoading(false);
  }) .catch((error) => {
    if(error && error.response && error.response.data && error.response.data.detail){
      alert(error.response.data.detail);
    }
    console.error("Error uploading image: ", error);
  });
  setTimeout(function() {
    setShowElement(false);
      window.location.reload();
  }, 2000);
      

    }
    const SubmitStoryData = ()=>{
      setIsLoading(true);

      //const tggg = selectedList;
      console.log(tagData, 'varun');
      var formData = new FormData();

      formData.append('title', inputData.title);


const getSubmitApi = `https://cms.iansnews.in/api/stories/${editId}/submit/`; 

axios({
  method: "post",
  url: getSubmitApi,
  data: formData,
  headers: { 
    "Content-Type": "multipart/form-data",
  Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,

},
  
}).then((res) => {
  setIsLoading(false);
  history.push('/');


    
});
    } 
    const FlagStoryData = ()=>{
      //setIsLoading(true);
      //const tggg = selectedList;
      console.log(tagData, 'varun');
      var formData = new FormData();

      formData.append('flag_message', 'falgged');



const flagApi = `https://cms.iansnews.in/api/stories/${editId}/flag/`;

axios({
  method: "post",
  url: flagApi,
  data: formData,
  headers: { 
    "Content-Type": "multipart/form-data",
  Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,

},
  
}).then((res) => {
 // setIsLoading(false);
  history.push('/');
    
});
    } 
    const uploadImage = (e)=>{
      e.preventDefault();

      let newccArry;

var formData = new FormData();
formData.append('title', uploadImg.title);
formData.append('keywords', uploadImg.keywords);
formData.append('caption', uploadImg.caption);
formData.append('free_photo', true);
formData.append('image', selectedFile);

const getUploadImgApi =`https://cms.iansnews.in/api/images/upload/`; 
setIsLoading(true)

axios({
method: "post",
url: getUploadImgApi,
data: formData,
headers: { "Content-Type": "multipart/form-data" },
}).then((res) => {
// const uploadImg =

newccArry ={
  id: res.data.data.id,
  image: res.data.data.image,
  keywords: res.data.data.keywords,
  title: res.data.data.title,
  caption: res.data.data.caption
}


setImgAry(imgArry => [newccArry, ...imgArry]);
setUploadImage({
  'title':'',
  'keywords':'',
  'caption':'',
})
setSelectedFile(null);
setIsLoading(false)
setImgModal(false);

});
setIsLoading(false)

}

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isload, setIsload] = useState(false)
    const [publishOpt, setPublishOpt] = useState(true)
    const PublishStoryData = ()=>{
      if(!publishOpt){
        alert("You have no permission for story published.");
        return false;
      }
      setMsg('');
      let nameArray = tagData && tagData.length > 0 && tagData.map(item => item.slug); // Extract 'name' property from each object
  
      let resultString = nameArray && nameArray.join(',').replace(/\s/g, '');
      if(!inputData.title){
        setMsg('News Heading can not be blank.')
        setAlertCss('alert-error')
        setShowElement(true)
      }else if(!inputData.slug){
        setMsg('Slug can not be blank.')
        setAlertCss('alert-error')
        setShowElement(true)
      }else if(!inputData.short_desc){
        setMsg('Short Description can not be blank.')
        setAlertCss('alert-error')
        setShowElement(true)
      }else if(!conData){
        setMsg('Content can not be blank.')
        setAlertCss('alert-error')
        setShowElement(true)
      }else if(!resultString){
        //alert('Category can not be blank.')  
        setMsg('Category can not be blank.')
        setAlertCss('alert-error')  
        setShowElement(true)  
      }else if(!inputData.topNews_category){
        setMsg('Main category can not be blank.')
        setAlertCss('alert-error')  
        setShowElement(true)   
      }else if(!inputData.image_caption){
        setMsg('Image caption can not be blank.')
        setAlertCss('alert-error')  
        setShowElement(true)           
      }else if(!inputData.images && (!imgArry || imgArry.length == 0)){
        setMsg('Image can not be blank.')
        setAlertCss('alert-error')  
        setShowElement(true)                    
      }else{
        setIsload(true)
        const getpublishApi = `https://cms.iansnews.in/api/stories/${editId}/publish/`; 

      axios({
        method: "post",
        url: getpublishApi,
        headers: { 
          "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,

      }}).then((res) => {
       // setIsLoading(false);
        //history.push('/');
      });
      WebPublishStoryData();
    }
}   

    const WebPublishStoryData = ()=>{
    const getwebpublishApi = `https://cms.iansnews.in/api/stories/${editId}/webpublish/`; 
    axios({
      method: "post",
      url: getwebpublishApi,
      headers: { 
        "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
    },
    }).then((res) => {
      if(res.data && res.data.status && res.data.status == 200){
        setIsload(false)
        history.push('/mystories')
      }

    });
    }  
     const [showElement, setShowElement] = useState(false);
     const removePTags = (html) => {
      return html
        .replace(/<\/p>/g, '\r\n \r\n') // Replace closing </p> tags with newline characters
        .replace(/<p>/g, '');    // Remove opening <p> tags
    };
    const mailSendData = (data)=>{
    
      if(data && data.current_status == 'CREATED'){
       var formMailData = new FormData()
       var tempTitle = data.title.replace("\r\n", " ");
       var accpt ="Accepted By";
       if(data.related_story){
        accpt = "Hindi Converted By"
       }
       const cleanedHtmlString = removePTags(data.summary);

       formMailData.append('title', tempTitle)
       formMailData.append('description', "Created_at : "+data.created_at+"\r\n "+accpt+" : "+ localStorage.userName+"\r\n \r\n"+cleanedHtmlString)
       //formMailData.append('created_at', data.created_at)
   
       const mailApi = `https://cms.iansnews.in/api/email-before-edit/`; 
       axios({
         method: "post",
         url: mailApi,
         data: formMailData,
         headers: { 
           "Content-Type": "multipart/form-data",
         Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
       },
       }).then((res) => {
         if(res.data && res.data.message && res.data.message == "Email sent successfully"){
           
           //history.push('/stories-edit/'+data.uuid+'/'+data.id)
         }
   
       })}
  } 
      useEffect(() => {
        if(localStorage && localStorage.langg  && (localStorage.langg=='hindi' || localStorage.langg=='ce')){
          fetchData(currentPage, localStorage.langg);
          
        }
        if(localStorage && localStorage.publishOpt && localStorage.publishOpt == "intern"){
          setPublishOpt(false)
        }
        setTimeout(function() {
          setShowElement(false);
          }, 3000);

        getWebSiteData();
        getStateData();
        getCategoryData();
        if(realtedStory){
          getRealtedStoryData(realtedStory);
        }
      }, [currentPage, realtedStory, msg]);
       
    return(
        <>
            <div className="wrapper">
            <Header />
           
            <div className="content-wrapper">
              <div className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                {showElement &&       
                    <div className="alert-m">
                      <p className={alertCss}>{msg}</p>
                    </div>
                    }
                    <div className="col-sm-10">
                      <h1 className="m-0">Manage News</h1>
                    </div>
                  </div>
                </div>
              </div>
              <section className="content">
                <div className="container-fluid">
                {!isload &&                   
                  <div className="col-lg-12">
                    <div className="card card-outline card-primary">
                      <div className="card-body">
										
                        <form>
                            <div className="form-row">
                            <div className="form-group col-md-12">
                              <label htmlFor="inputHeading">News Heading: *</label>
                              <input type="text" onChange={handleOnChange} name="title" className="form-control" id="inputHeading" value={inputData.title}/>
                            </div>
                            <div className="form-group col-md-12">
                              <label htmlFor="inputSlug">Slug *</label>
                              <input type="text" className="form-control" onChange={handleOnChange} name="slug" value={inputData.slug}/>
                            </div>
                            </div>
                            <div className="form-group">
                            <label htmlFor="inputSummary">Summary</label>

                            <div className="form-control form-control-user editContEn-summary" rows="10" id="editShortDescEn" readOnly="" dangerouslySetInnerHTML={{ __html: inputData.summary }}></div>
                            </div>

                            <div className="d-md-flex">
                                <div className="w-100">
                                    <div className="form-group">
                                        <label htmlFor="editShortDesc">Short Description *</label>
                                        <textarea className="form-control form-control-user editShortDesc" placeholder="Description" rows="7" name="short_desc" onChange={handleOnChange} value={inputData.short_desc}> </textarea>
                                    </div>
                                </div>
{                                realData && realData.short_desc &&
                            <div className="w-100 editShortDescEn ml-3">
                                    <div className="form-group">
                                    <label htmlFor="editShortDescEn">Short Description(EN)</label>
                                    <textarea disabled className="form-control form-control-user editShortDescEn" rows="7" id="editShortDescEn" readOnly="">{realData.short_desc }
                                    </textarea>
                                    </div>
                                </div>}
                            </div>

                            <div className="d-md-flex">
                                <div className="w-100">
                                    <div className="form-group">
                                        <label htmlFor="editShortDesc">Main Content *</label>
                                       
                                       <div>
                                        <ReactQuill className="form-control form-control-user editShortDesc" 
                                        theme="snow" 
                                        name="content" 
                                        value={conData} 
                                        onChange={setValue}
                                         
                                        />
                                        <p className="form-word-count">Word Count: {wordCount}</p></div>

                                        
                                        {/* <textarea  placeholder="Description" > </textarea> */}
                                    </div>
                                </div>
                              {  realData && realData.content &&
                               <div className="w-100 editShortDescEn ml-3">
                                    <div className="form-group">
                                    <label htmlFor="editShortDescEn">Main Content(EN)</label>

                                    <div className="form-control form-control-user editContEn" rows="23" id="editShortDescEn" readOnly="" dangerouslySetInnerHTML={{ __html: realData.content }}></div>
                                    </div>
                                </div>}
                            </div>
                          
                            <div className="form-group">
                            <div className="form-check">
                            <input
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleChecked}
                                className="form-check-input"
                              />
                              {/* <input className="form-check-input" checked="true" name="top_story" onChange={handleChecked} /> */}
                              <label className="form-check-label" htmlFor="gridCheck">
                              Top Story{isChecked}
                              </label>
                            </div>
                            </div>
                            
                            <div className="form-row">
                            <div className="form-group col-md-2">
                              <label htmlFor="inputByline">Byline</label>
                              <input type="tagDataext" className="form-control" onChange={handleOnChange} name="byline" value={inputData.byline}/>
                            </div>
                            <div className="form-group col-md-5">
                              <label htmlFor="inputCategory">Category *</label>
                                <Multiselect
                                  options={cattData}
                                  displayValue="slug"
                                  selectedValues={tagData}
                                  onSelect={(selectedList) => setTagData(selectedList)}
                                  onRemove={(selectedList) => setTagData(selectedList)}
                                />
                            </div>
                            
                            <div className="form-group col-md-5">
                              <label htmlFor="inputTLCategory">Main Category *</label>
                              <select className="form-control" name="topNews_category" onChange={handleOnChange} value={inputData.topNews_category}>
                                <option selected="" value="">-- Select Main Category --</option>
                                {mainCate && mainCate.length > 0 && mainCate.map((catt, c)=>{
                                  return(
                                    <option value={catt.slug} key={c}>{catt.name}</option>
                                  )
                                })}
                              </select>
                            </div>
                            </div>
                            
                            <div className="form-group">
                              <label htmlFor="inputKeywords">Keywords</label>
                              <input type="text" className="form-control" onChange={handleOnChange} name="keywords" value={inputData.keywords}/>
                            </div>
                            <div className="form-group">
                               <label htmlFor="inputKeywords">Main Image Caption *</label>
                                <input type="text" className="form-control" onChange={handleOnChange} name="image_caption" value={inputData.image_caption}/>
                            </div>                            
                            <div className="form-group">
                              <label htmlFor="exampleInputTitle">Photo</label>
                              <div className="input-group-append">
                                <input type="text" className="form-control" placeholder="Search keyword" name="name" value={searchImage.name} onChange={handleOnImgChange}/>
                                <Button variant="primary" className="btn btn-primary" onClick={()=>{
                                  getSearchImg(1)}}>
                                 <i className="fa fa-search"></i>
                                 </Button>
                              </div>
                            </div>
                            
                            <div className="row">
                              <div className="col-md-12">
                                <ul className="list-pic list-pic-edit">
                                  {imgArry && imgArry.length > 0 && imgArry.map((imgg, im)=>{
                                    return(
                                      
                                  <li key={im} >
                                    <img src={imgg.image}/>
                                    <div className="editpho-icon">
                                      <a href={'#'} onClick={()=>{
                                    removeImage(imgg.id)}}>
                                        <i className="fa fa-trash" aria-hidden="true"></i>
                                      </a>
                                    </div>
                                    <p>{imgg.caption}</p>
                                  </li>                            
                                     
                                    )
                                  })}                                 
                                </ul>
                              </div>
                            </div>
                            <label htmlFor="inputKeywords">OR</label>
                            <div className="accordion" >
                              <button className="addimg-btn btn btn-primary" type="button" onClick={()=>{
                                setImgModal(!imgModal)
                              }}>
                              + Add Images
                              </button>
                              {isLoading     && 
                                <div className="load-img">
                                  Uploading...
                                  <div className="loader"></div>
                                </div>  
                              }	
                          {imgModal &&
                            <div className="" id="">
                              <div className="card card-body">
                              {/* <form encType="multipart/form-data" className="border p-2 mb-2 rounded">
                             
                                    <div className="form-row">
                                    <div className="form-group col-md-2">
                                      <label htmlFor="files">Photos</label>
                              
                                          <input type="file" onChange={handleFileChange} name="image" required=""/>


                                    </div>
                                    <div className="form-group col-md-10">
                                      <label htmlFor="imageKeyword">Image Keyword</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="keywords"
                                        onChange={handleUpload}
                                        value={uploadImg.keywords}
                                        placeholder="Enter your image keywords"
                                        required
                                      />
                                    </div>
                                    <div className="form-group col-md-12">
                                      <label htmlFor="imageCaption">Image Caption</label>
                                      <textarea
                                        className="form-control"
                                        name="caption"
                                        onChange={handleUpload}
                                        value={uploadImg.caption}
                                        placeholder="Enter caption"
                                      />
                                    </div>
                                  </div>
                                  
                                  <div className="form-row">
                                    <button className="btn btn-success" onClick={uploadImage}>
                                      Add
                                    </button>
                                    <button className="btn btn-secondary" type="button">
                                      Cancel
                                    </button>
                                  </div>
                                </form> */}
                                <form method="post" action="" encType="" className="border p-2 mb-2 rounded">
                                <div className="form-row">
                                  <div className="form-group col-md-2">
                                    <label htmlhtmlFor="files">Photo</label>
                                    <input type="file" onChange={handleFileChange} name="image" required="" accept="image/jpeg, image/jpg"/>
                                  </div>
                                  <div className="form-group col-md-5">
                                    <label htmlhtmlFor="imageKeyword">Image Keyword</label>
                                    <input type="text" className="form-control" name="keywords" onChange={handleUpload} value={uploadImg.keywords}  placeholder="Enter your image keyword" required=""/>
                                  </div>
                                  <div className="form-group col-md-5">
                                    <label htmlhtmlFor="imageCaption">Image Caption</label>
                                    <input type="text" className="form-control" name="caption" onChange={handleUpload} value={uploadImg.caption}  placeholder="Enter caption"/>
                                  </div>
                                </div>
                                <div className="form-row">
                                  <a className="btn btn-success" href="#" onClick={uploadImage}>Add</a>
                                  <button className="btn btn-secondary" type="button" onClick="#">Cancel</button>
                                </div>
                              </form>

                              </div>
                            </div>}  
                            </div>

                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label htmlFor="inputState">State</label>
                                <select 
                                className="custom-select" 
                                onChange={handleOnStateMultiSelectChange} 
                                multiple
                                name="states"
                                value={selectedStateOptions}> 
                                {stateData && stateData.length > 0 && stateData.map((stt, s)=>{
                                      return(
                                        
                                        <option value={stt.id} key={s}>{stt.name}</option>         
                                        
                                      )
                                    })}                               
                                </select>
                              </div>
                              
                              <div className="form-group col-md-6">
                                  <label htmlFor="inputWebsite">Website</label>
                                  <select
                                    className="custom-select"
                                    onChange={handleOnMultiSelectChange}
                                    multiple
                                    name="websites"
                                    value={selectedOptions}
                                  >
                                    {webData && webData.length > 0 && webData.map((webb, w)=>{
                                      return(
                                        <option value={webb.name} key={webb.name}>
                                        {webb.name}
                                        </option>
                                      )
                                    })}
                                  </select>
                              </div>
                            </div>
                        </form>
                          <div className="col-md-12 text-center mt-4 pub-btn-fixed">
                            <button className="btn btn-secondary savebtn" type="submit" onClick={SaveStoryData}>Save</button>
                            <button className="btn btn-warning"  type="button" onClick={SubmitStoryData}>Submit Review</button>
{  publishOpt  &&                      
                             <button className="btn btn-primary"  type="button"onClick={PublishStoryData}>Publish</button>
}                            <button className="btn btn-danger" onClick={FlagStoryData} type="button">Flag</button>
                          </div>
                      </div>
                    </div>
                  </div>}
                  <div className="col-md-12">
                            {isload && 
                <div className="load-img">
                Uploading...
                <div className="loader"></div>
              </div>
                }
                            </div>
                </div>
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header >
                    <button onClick={handleClose} className="close" role="button" tabIndex="0" >&times;</button>
                  <div className="form-group">
                    <label htmlFor="exampleInputTitle">Story Photo Mapping </label>
                    <div className="input-group-append">
                      <input type="text" className="form-control"  placeholder="Search" name="name" value={searchImage.name} onChange={handleOnImgChange}/>
                      <button type="button" className="btn btn-primary" onClick={()=>{getSearchImg(currentImgPage)}}>
                        <i className="fa fa-search"></i>
                      </button>
                    </div>
                  </div>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="container">
                    <div className="row">
                      <ul className="img-popup-photo" data-choose-image="">
                        {imgSearchD && imgSearchD.length > 0 && imgSearchD.map((imgD, img) =>{
                          return(
                              <li key = {img}>
                                <a href={imgD.image} target="_blank">
                                  <img className="img-fluid" src={imgD.image}/>
                                </a>
                                  <p className="search_caption">{imgD.title}</p>
                                <input type="checkbox" onChange={handleChange} className="selectImage" name="languages" value={imgD.id}/>
                              </li>                  
                          )
                        })}

                      </ul>
                      <div id="pagination-search" className="pagination-outer">
                        <ul className="pagination bootpag">
                          <li className="prev page-item" onClick={()=>{handleImgPrevPage(currentImgPage-1)}}>
                            <a href="#"  className="page-link"><i className="fa fa-arrow-left" aria-hidden="true"></i></a>
                          </li>
{     currentImgPage && currentImgPage > 1 && 
                     <li className=" page-item" >
                            <a href="#" onClick ={()=>{
                              imgSetPage(currentImgPage-1)
                            }} className="page-link">{currentImgPage-1}</a>
                          </li>}
                          <li className="active page-item" >
                            <a onClick ={()=>{
                              imgSetPage(currentImgPage)
                            }} href="#" className="page-link">{currentImgPage}</a>
                          </li>
                          <li className="page-item">
                            <a href="#" onClick ={()=>{
                              imgSetPage(currentImgPage +1)
                            }} className="page-link">{currentImgPage +1}</a>
                          </li>
                          <li className="next page-item"  onClick={()=>{handleImgNextPage(currentImgPage+1)}}>
                            <a href="#" className="page-link"> <i className="fa fa-arrow-right" aria-hidden="true"></i></a>
                          </li>
                        </ul>
                      </div>
                      </div>
                  </div>
                  </Modal.Body>
                </Modal>
              </section>
            </div>
            <Footer />
            </div>
        </>
    )
}


export default StoryEdit