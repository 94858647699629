import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import {Helmet} from "react-helmet";
import { useHistory } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Multiselect from 'multiselect-react-dropdown'; // Adjust according to your Multiselect library



const AddVideo = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({
    title: '',
    slug: '',
    language: '',
    description: '',
    top_video: false,
    keywords: '',
    tags: [],
    updateVideoDuration: false, // New state for the checkbox
  });

  
  const [tagsList, setTagsList] = useState([]);
  const [loadingTags, setLoadingTags] = useState(true);

  const [cattData, setCattData] = useState([]); // For storing available categories
  const [tagData, setTagData] = useState([]); // For storing selected categories

  useEffect(() => {
    // Fetch tags
    const fetchTags = async () => {
      // ... existing fetch logic for tags
    };

    // Fetch categories
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://cms.iansnews.in/api/stories/tags/', {
          headers: {
            Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken : ''}`,
          },
        });
        setCattData(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchTags();
    fetchCategories(); // Call to fetch categories
  }, []);

  
  // const handleChange = (e) => {
  //   const { name, value, type, checked } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: type === 'checkbox' ? checked : value,
  //   });
  // };
  const handleChange = event => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleTagsChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions).map(option => parseInt(option.value));
    setFormData({
      ...formData,
      tags: selectedOptions,
    });
  };
  const [conData, setValue] = useState('');

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };
  const getFormattedDate = () => {
    const date = new Date();
    
    // Get individual date and time components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    // Concatenate the components into the desired format
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  };
  
  // Usage
  const formattedDate = getFormattedDate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const tags_ids = tagData.map(tag => tag.id);

    let { slug, title, language, top_video,  updateVideoDuration, keywords } = formData;
    slug = slug.toLowerCase();
    slug = slug.replace(" ", "-");
    slug = slug.replace(" ", "-");
    slug = slug.replace(" ", "-");
    slug = slug.replace(" ", "-");
    slug = slug.replace(" ", "-");
    slug = slug.replace(" ", "-");
    slug = slug.replace(" ", "-");
    slug = slug.replace(" ", "-");
    slug = slug.replace(" ", "-");

    const newslug =`${slug}-${formattedDate}`
    const description = conData;
    // Creating the formatted file names based on the slug
    const thumbnail_name = `${slug}.jpg`;
    const video_name = `${slug}.mp4`;
    const preview_name = `${slug}.mp4`;
    const added_by_id = localStorage && localStorage.length > 0 && localStorage.userId ? localStorage.userId : null
    const video_duration = updateVideoDuration ? `${slug}-raw.mp4` : null; // Set only if checked

    // Construct the full API payload
    const payload = {
      title,
      description,
      thumbnail_name,
      video_name,
      slug: newslug,
      preview_name,
      language,
      top_video,
      keywords,
      tags_ids,
      added_by_id,
    };

    // Add video_duration only if updateVideoDuration is true
    if (updateVideoDuration) {
      payload.video_duration = video_duration;
    }

    try {
      const response = await axios.post('https://cms.iansnews.in/api/video-portal/', payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken : ''}`,
        },
      });
      if (response.status === 201) {
        console.log('Success:', response.data);
        history.push('/all-videos'); // Redirect to the home page on success
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
<Helmet>
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <link rel="icon" href="icon-new.ico" type="image/x-icon" />
  <link rel="shortcut icon" href="/theme/Ianstv/images/favicon.ico" type="image/x-icon" />
  <meta data-meta-title="Ianstv.in" name="keywords" content="Ianstv news, Ianstv updates, Ianstv celebrities, Hindi cinema, Indian film industry, Ianstv gossips, Ianstv rumors, Ianstv releases, Movie reviews, Celebrity interviews" />
  <meta data-meta-title="Ianstv.in" name="description" content="Stay up-to-date with the latest Ianstv news and updates. Get the hottest gossips, rumors, movie reviews, and exclusive celebrity interviews from the captivating world of Hindi cinema. Your one-stop destination for everything Ianstv." />
</Helmet>
  <div className="wrapper">
  <Header />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-10">
                <h1 className="m-0">Add Video</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="col-lg-12">
              <div className="card card-outline card-primary">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="inputHeading">Video Heading: *</label>
                        <input
                          type="text"
                          name="title"
                          className="form-control"
                          id="inputHeading"
                          value={formData.title}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <label htmlFor="inputSlug">File Name *</label>
                        <input
                          type="text"
                          className="form-control"
                          name="slug"
                          value={formData.slug}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="d-md-flex">
                        <div className="w-100">
                            <div className="form-group">
                                <label>Main Content *</label>
                                
                                <div className='video-editor'>
                                <ReactQuill className="form-control form-control-user editShortDesc" 
                                theme="snow" 
                                type="text"
                                name="description" 
                                value={conData} 
                                onChange={setValue}/>
                                
                               </div>

                                
                                {/* <textarea  placeholder="Description" > </textarea> */}
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-12">
                        <label htmlFor="inputSlug">Keywords/Hashtags *</label>
                        <textarea
                          type="text"
                          className="form-control"
                          name="keywords"
                          value={formData.keywords}
                          onChange={handleChange}
                          rows="6"
                        />
                      </div>
                    <div className="form-group">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name="updateVideoDuration"
                          checked={formData.updateVideoDuration}
                          onChange={handleCheckboxChange}
                        />
                        <label className="form-check-label">RAW Video</label>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="inputLanguage">Language *</label>
                        <select
                          className="form-control"
                          name="language"
                          value={formData.language}
                          onChange={handleChange}
                        >
                          <option value="">-- Select Language --</option>
                          <option value="English">English</option>
                          <option value="Hindi">Hindi</option>
                        </select>
                      </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="inputCategory">Category *</label>
                          <Multiselect
                            options={cattData}
                            displayValue="slug" // Adjust based on your data structure
                            selectedValues={tagData}
                            onSelect={(selectedList) => setTagData(selectedList)}
                            onRemove={(selectedList) => setTagData(selectedList)}
                          />
                      </div>
                    </div>
                    <button className="btn btn-primary" type="submit">Save</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  </>
  );
};

export default AddVideo;
