const Test=()=>{
    const sampleData = `
    <div>
      <h1>Sample Testing Data</h1>
      <p>This is a sample text with various HTML tags.</p>
      <ul>
        <li>Item 1</li>
        <li>Item 2</li>
        <li>Item 3</li>
      </ul>
      <p>More <span style="color: blue;">styled</span> text.</p>
      <p>Here's a table:</p>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Age</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>John</td>
            <td>30</td>
          </tr>
          <tr>
            <td>Mary</td>
            <td>25</td>
          </tr>
          <tr>
            <td>Bob</td>
            <td>35</td>
          </tr>
        </tbody>
      </table>
      <p>More <span style="color: blue;">styled</span> text.</p>
    </div>
  `;
  const cleanText = sampleData.replace(/<(?!\/?p\b)[^>]+>/g, '');
  return(
    <>
    {cleanText}
    </>
  )
  }
  export default Test