import React, { useState } from 'react';

import Modal from 'react-bootstrap/Modal';


function ModalNewContent({ data, onHide }) {
  return (
    <Modal show={!!data} onHide={onHide} className='count-modal'>
      <Modal.Header closeButton>
        <Modal.Title>Today's Stories Count</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        {data ? (
          <table className="table table-striped table-bordered table-hover">
            <tbody>
              <tr>
                <td className="stories-count-hed">Category Name</td>
                <td className="stories-count-hed">Stories Count</td>
              </tr>
              {Object.keys(data.details).map(category => (
                <tr key={category}>
                  <td>{category}</td>
                  <td>{data.details[category]}</td>
                </tr>
              ))}
              <tr>
                <td className="stories-count-hed">Total</td>
                <td className="stories-count-hed">{data['today\'s Count']}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          <p>Loading data...</p>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ModalNewContent
