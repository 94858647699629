import React,{useState, useEffect} from 'react';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import {NavLink} from 'react-router-dom';
import {Helmet} from "react-helmet";
import ModalNewContent from './ModalNewContent'
import Button from 'react-bootstrap/Button';
import API_URL from './ApiData'
import { useHistory } from 'react-router-dom'

const Home =()=>{
  const [showElement, setShowElement] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory()
  const [alertCss, setAlertCss]= useState('')
  const [msg, setMsg] = useState('');
  const [modalData, setModalData] = useState(null);
  const [count, setCount] = useState(0);
  const [reload, setreload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [userData, setUserData] = useState();
  const [pageList, setPageList] = useState([1,2,3]);
  const [lt, setLt] = useState(5);
  const [lastPage, setLastPage] = useState(10);
  const [assignUser, setAssignUser] = useState({
    userId :null
  })
  const [allStoryList, SetAllStoryData] = useState([]);
  const [userinfo, setUserInfo] = useState({
    languages: [],
    response: [],
  });
  const handleNextPage = () => {
    if(lastPage > currentPage){
      setCurrentPage(currentPage + 1);
      let newArry = pageList;
      let newAddArray = pageList[pageList.length-1]+1;
      // if(currentPage > 2){
        let newRemoveArray = pageList[0];
      //
      // }
      newArry.shift(newRemoveArray);
      newArry.push(newAddArray);
        setPageList(newArry);
        }
    }
  const handleOnChange = event => {
    const { name, value } = event.target;
    setAssignUser({ ...assignUser, [name]: value });
  };      
  const handleAssign = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { languages } = userinfo;
    if (checked) {
      setUserInfo({
        languages: [...languages, value],
        response: [...languages, value],
      });
    }
    // Case 2  : The user unchecks the box
    else {
      setUserInfo({
        languages: languages.filter((e) => e !== value),
        response: languages.filter((e) => e !== value),
      });
    }
  };  
  const mailSendData = (data)=>{
    if(data && data.current_status == 'CREATED'){
     var formMailData = new FormData()
     var tempTitle = data.title.replace("\r\n", " ");
     
     formMailData.append('title', tempTitle)
     formMailData.append('description', "Created_at : "+data.created_at+"\r\n Accepted By : "+ localStorage.userName+"\r\n \r\n"+data.summary)
     //formMailData.append('created_at', data.created_at)
 
     const mailApi = `https://cms.iansnews.in/api/email-before-edit/`; 
     axios({
       method: "post",
       url: mailApi,
       data: formMailData,
       headers: { 
         "Content-Type": "multipart/form-data",
       Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
     },
     }).then((res) => {
       if(res.data && res.data.message && res.data.message == "Email sent successfully"){
         
         history.push('/stories-edit/'+data.uuid+'/'+data.id)
       }
 
     })
    }else{
     history.push('/stories-edit/'+data.uuid+'/'+data.id)
    }

}    
  const handlePrevPage = () => {
  if(currentPage > 1){
  
    setCurrentPage(currentPage - 1);
    // setCurrentPage(currentPage + 1);
    let newArry = pageList;
  
    let newAddArray = pageList[pageList.length-1];
    let newRemoveArray = pageList[0]-1;
      newArry.unshift(newRemoveArray);
    newArry.pop(newAddArray);
  setPageList(newArry);
  }
  }
  const handleLastPage = () => {
  //if(requestFlag){
    setCurrentPage(lt);
    let lastArrtty = [lt-3, lt-2, lt-1]
    setPageList(lastArrtty);
  //  }
  }
  const handleStartPage = () => {
  // if(requestFlag){
    setCurrentPage(1);
    let lastArrtty = [1, 2, 3]
    setPageList(lastArrtty);
  //  }
  }
  const handleSetPage = (page) => {
  //  if(requestFlag){
    setCurrentPage(page);
  //}
  }
  const handleOpenModal = async () => {
    try {
      let ln = localStorage && localStorage.langg ? localStorage.langg : ''; 
      ln = ln =='ce' ? 'english' :ln; 

      const response = await axios.get(API_URL.getStoryCount+ln);
      setModalData(response.data);
      setShowModal(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setModalData(null); // Reset modal data when closing
  };

  const fetchData = async (page, ln) => {
    ln = ln =='ce' ? 'english' :ln; 
    const allStoryAPI =`https://cms.iansnews.in/api/list-stories/?page=${page}&language=${ln}`; 

      axios
      .get(
          allStoryAPI
      )
      .then((res) => {
      const allStoryData =
          res.data.data &&
          res.data.data.length > 0 &&
          res.data.data.map((item) => {
          return {
              id: item.id,
              title: item.title,
              current_status: item.current_status,
              slug:'/'+item.slug,
              uuid:item.uuid,
              summary:item.summary,
              user_edited_by : item.user_edited_by,
              created_at:item.created_at,
          };
          });
      SetAllStoryData(allStoryData);
      });
  }
  const postAssignUser=()=>{
    setIsLoading(true)
    if(!assignUser || !assignUser.userId || assignUser.userId == '0'){
      setMsg('Please select user.')
      setAlertCss('alert-error')
      setShowElement(true)
    }else if(userinfo.response && userinfo.response.length == 1){
      const getStateApi =`https://cms.iansnews.in/api/stories/assign-story/${userinfo.response}/`; 


      fetch(getStateApi, {
        method: 'POST',
        body: JSON.stringify({
            user_id: assignUser.userId,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
                Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,

        },
      }).then((res) => {
      //console.log('vikas', res);
      if(res && res.status==200){
        setTimeout
        (window.location.href = "/", 5000);
        setAssignUser({
          userId:null
        })
        setUserInfo({
          languages: [],
          response: [],
        })
        setMsg('Story Assign successfully.')
        setAlertCss('alert-success')
        setShowElement(true)
      }

      //fetchData(currentPage, localStorage.langg);
      });
    }else if(userinfo.response && userinfo.response.length > 1){
      setMsg('Please select only one story.')
      setAlertCss('alert-error')
      setShowElement(true)
        //alert('Please select only one story.');
    }else{
      setMsg('Please select one story minimum.')
      setAlertCss('alert-error')
      setShowElement(true)
    }
    // var formData = new FormData();



  }
  const getUserData = (ln)=>{
    const setUserApi =API_URL.getUserData+ln; 

      axios
      .get(
        setUserApi
      )
      .then((res) => {
      const stateD =
          res.data.data &&
          res.data.data.length > 0 &&
          res.data.data.map((item) => {
          return {
              id: item.id,
              groups: item.groups,
              name: item.name,
              email: item.email,
              username: item.username
          };
          });
          setUserData(stateD);
      });      
  }   
    useEffect(() => {
      setTimeout(function () {
              if (modalData) {
          const totalCount = modalData['today\'s Count'];
          setCount(totalCount);
        }
        setTimeout(function() {
          setShowElement(false);
          }, 3000);
      if(localStorage && localStorage.langg && (localStorage.langg=='hindi' || localStorage.langg=='ce')){
        // setLangDefault(localStorage.langg);
        fetchData(currentPage, localStorage.langg);
        getUserData(localStorage.langg);
      }
      //localStorage.setItem('reloadd', false);
    }, 800);
      }, [currentPage, modalData, msg]);
    return(
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <link rel="icon" href="icon-new.ico" type="image/x-icon" />
                <link rel="shortcut icon" href="/theme/Ianstv/images/favicon.ico" type="image/x-icon" />
                <meta data-meta-title="Ianstv.in" name="keywords" content="Ianstv news, Ianstv updates, Ianstv celebrities, Hindi cinema, Indian film industry, Ianstv gossips, Ianstv rumors, Ianstv releases, Movie reviews, Celebrity interviews" />
                <meta data-meta-title="Ianstv.in" name="description" content="Stay up-to-date with the latest Ianstv news and updates. Get the hottest gossips, rumors, movie reviews, and exclusive celebrity interviews from the captivating world of Hindi cinema. Your one-stop destination for everything Ianstv." />
            </Helmet>
            <div className="wrapper">
            <Header />
              <div className="content-wrapper">
                <div className="content-header">
                  <div className="container-fluid">
                    <div className="row mb-2">
                    {showElement &&  
                      <div className="alert-m">
                        <p className={alertCss}>{msg}</p>
                      </div>
                    }
                      <div className="col-sm-9">
                        <h1 className="m-0">All Stories</h1>
                      </div>
                      <div className="col-sm-3">
                        <>
                         <Button className="fade toast show ml-auto mx-auto" role="alert" aria-live="assertive" aria-atomic="true" variant="primary" onClick={handleOpenModal}>
                          Today's Stories Count </Button>
                          <ModalNewContent data={modalData} onHide={handleCloseModal} />
                        </>
                        </div>
                    </div>
                  </div>
                </div>
                <section className="content">
                  <div className="container-fluid">
                    <div className="col-lg-12">
                      <div className="card card-outline card-primary">
                        <div className="card-body">
                        <div className="col-md-12 mt-4">
                            <select className="assign-s" name="userId" value={assignUser.userId} onChange={handleOnChange}>
                              <option value="0" className="assign-hed" >Assign To:</option>
                              {userData && userData.length > 0 && userData.map((usee, u)=>{
                                return(
                                   <option value={usee.id} key={u} >{usee.username}</option>
                                )
                              })}
                            </select>
                              <button className="btn btn-primary btn-Assign" onClick={postAssignUser} type="button">Assign</button>
                            </div>
                          <table className="table tabe-hover table-bordered table-compact" id="">
                            <colgroup dangerouslySetInnerHTML={{
                                __html: `
                                <col width="5%">
                                <col width="2%">
                                <col width="32%">
                                <col width="15%">
                                <col width="15%">
                                <col width="15%">
                                <col width="15%">
                                `
                              }}/>
                            <thead>
                              <tr>
                                <th className="text-center">ID</th>
                                <th>Assign</th>
                                <th>Heading</th>
                                <th>Edited By</th>
                                <th>Current Status</th>
                                <th>Last Modified</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>		
                              {
                                allStoryList && allStoryList.length > 0 && allStoryList.map((allSty, c)=>{
                                  return(
                                  
                                <tr key = {c}>
                                <th className="text-center">{allSty.id}</th>
                                <th className="text-center">
                                  <label className="container">
                                    {allSty.current_status == 'CREATED' && 
                                    
                                    <input type="checkbox" onChange={handleAssign}  name="assignnn" value={allSty.uuid}/>
                                    }
                                    <span className="checkmark"></span>
                                  </label>
                                </th>
                                <td>{allSty.title}</td>
                                <td>{allSty.user_edited_by.username}</td>
                                <td>{allSty && allSty.current_status && <span className={allSty.current_status == 'PENDING' ? 'cur-status pending': allSty.current_status == 'CREATED' ? 'cur-status new_mail' : allSty.current_status == 'ASSIGNED' ? 'cur-status assigned' : allSty.current_status == 'PUBLISHED' ? 'cur-status published' : allSty.current_status.substr(0, 8) =='ACCEPTED' ? 'cur-status accpeted' : allSty.current_status == 'FLAGGED' ? 'cur-status flagged' :'cur-status being_edited'}>{allSty.current_status}</span>}</td>
                                <td>{allSty.created_at}</td>
                                <td>
                                  <div className="btn-group">
                                  <NavLink to= {'/stories-preview/'+allSty.uuid} className="btn btn-view btn-flat btn-sm manage_service" target="_blank"><i className="fa fa-eye viewedit" aria-hidden="true"></i></NavLink>
{                                   !allSty.user_edited_by || !allSty.user_edited_by.username &&
                                    // <button onClick={()=>{
                                    //   mailSendData(allSty)
                                    // }} className="btn btn-primary btn-flat btn-sm manage_service"><i className="fas fa-edit"></i></button>							  
<NavLink to={'/stories-edit/'+allSty.uuid+'/'+allSty.id}  className="btn btn-primary btn-flat btn-sm manage_service"><i className="fas fa-edit"></i></NavLink>
}                                    
                                  </div>
                                </td>
                              </tr>
                                  )
                                })
                              }										
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="pagination">
                      <ul>
                          {currentPage == 1  && <li onClick={handleStartPage} disabled={currentPage === 1}><NavLink className="prevnext disablelink" to="#">Previous</NavLink></li>}
                          {currentPage != 1  &&<li onClick={handlePrevPage} ><a href="#">Previous</a></li>}
                          {pageList.map((data, i)=>{
                          return(
                                <li key={i} onClick={()=>{
                          setCurrentPage(data)}}><a href="#" className={currentPage == data ? 'active' : ''}>{data}</a></li>
                                      )
                                  })}
                          <li onClick={handleNextPage}><a href="#" className="prevnext">Next</a></li>
                      </ul>                                            							
                    </div>                    
                  </div>
                </section>
              </div>
              <Footer />
            </div>
        </>
    )
}

export default Home