import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import { NavLink } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import { format, parseISO } from 'date-fns'

const AllVideo = () => {
  const [showElement, setShowElement] = useState(false);
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [alertCss, setAlertCss] = useState('');
  const [msg, setMsg] = useState('');
  const [modalData, setModalData] = useState(null);
  const [count, setCount] = useState(0);
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [userData, setUserData] = useState();
  const [pageList, setPageList] = useState([1, 2, 3]);
  const [lt, setLt] = useState(5);
  const [lastPage, setLastPage] = useState(10);
  const [publishedObj, setPublishedByUser] = useState();
  const [assignUser, setAssignUser] = useState({
    userId: null
  });
  const [allStoryList, setAllStoryData] = useState([]);
  const [userinfo, setUserInfo] = useState({
    languages: [],
    response: []
  });
  const [pageLoad, setPageLoad] = useState(false);
  const [uploaderContent, setUploaderContent] = useState({
    title: '',
    description: '',
    thumbnail_name: '',
    video_name: '',
    slug: '',
    preview_name: '',
    is_active: false,
    language: '',
    top_video: false,
    tags_ids: [],
    video_duration: '',
    added_by_id: null,
    editor_name_id: localStorage && localStorage.length > 0 && localStorage.editor && localStorage.userId ? localStorage.userId : null,
    uploader_name_id: null
  });


  const handleNextPage = () => {
    if(lastPage > currentPage){
      setCurrentPage(currentPage + 1);
      let newArry = pageList;
      let newAddArray = pageList[pageList.length-1]+1;
      // if(currentPage > 2){
        let newRemoveArray = pageList[0];
      //
      // }
      newArry.shift(newRemoveArray);
      newArry.push(newAddArray);
        setPageList(newArry);
        }
  }
      
     
  const handlePrevPage = () => {
  if(currentPage > 1){
    setCurrentPage(currentPage - 1);
    // setCurrentPage(currentPage + 1);
    let newArry = pageList;
    let newAddArray = pageList[pageList.length-1];
    let newRemoveArray = pageList[0]-1;
      newArry.unshift(newRemoveArray);
    newArry.pop(newAddArray);
  setPageList(newArry);
  }
  }


  const handleStartPage = () => {
  // if(requestFlag){
    setCurrentPage(1);
    let lastArrtty = [1, 2, 3]
    setPageList(lastArrtty);
  //  }
  }

  // Function to determine the status based on conditions
  const getStatus = (added_by, editor_name, uploader_name) => {
    if (added_by && !editor_name && !uploader_name) {
      return "Pending with editor";
    } else if (added_by && editor_name && !uploader_name) {
      return "Accepted by editor";
    } else if (added_by && editor_name && uploader_name) {
      return "Published";
    }
    return "Status Unknown";
  };

  // Function to get the status CSS class based on the status
  const getStatusClass = (status) => {
    switch (status) {
      case "Pending with editor":
        return "status-pending";
      case "Accepted by editor":
        return "status-accepted";
      case "Published":
        return "status-published";
      default:
        return "";
    }
  };

  const acceptSubmit = (id) => {
    const selected = allStoryList.filter((video) => video.id === id);
    if (selected.length > 0) {
      let tempData = {
        title: selected[0].title,
        description: selected[0].description,
        thumbnail_name: selected[0].thumbnail_name,
        video_name: selected[0].video_name,
        slug: selected[0].slug,
        preview_name: selected[0].preview_name,
        is_active: selected[0].is_active,
        language: selected[0].language,
        top_video: selected[0].top_video,
        tags_ids: selected[0].tags,
        video_duration: selected[0].video_duration,
        added_by_id: selected[0].added_by_id,
        editor_name_id: localStorage && localStorage.length > 0 && localStorage.editor && localStorage.userId ? localStorage.userId : null,
        uploader_name_id: selected[0].uploader_name_id
      };

      axios({
        method: "put",
        url: `https://cms.iansnews.in/api/video-portal/${id}/`,
        data: tempData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken : ''}`
        }
      }).then((res) => {
        setPageLoad(true);
        history.push('/all-videos');
      });
    }
  };
  const formatDate = (timestamp)=>{
    const date = parseISO(timestamp)
    return format(date, 'MMMM dd, yyyy h:mm a')
}
  const fetchData = async (page, ln) => {
    if (currentPage <= lastPage) {
      ln = ln === 'ce' ? 'english' : ln;
      const allStoryAPI = `https://cms.iansnews.in/api/video-portal/list/?page=${currentPage}`;

      axios.get(allStoryAPI)
        .then((res) => {
          const allStoryData = res.data.results.map((item) => {
            return {
              id: item.id,
              title: item.title,
              current_status: item.current_status,
              slug: item.slug,
              added_by: item.added_by && item.added_by.name ? item.added_by.name : '',
              added_by_id: item.added_by && item.added_by.id ? item.added_by.id : null,
              editor_name: item.editor_name,
              editor_name_id: item.editor_name && item.editor_name.id ? item.editor_name.id : null,
              uploader_name: item.uploader_name,
              uploader_name_id: item.uploader_name && item.uploader_name.id ? item.uploader_name.id : null,
              
              
              created_at: item.created_at ? formatDate(item.created_at): '',

              description: item.description,
              video_duration: item.video_duration,
              acceptAccess: item.editor_name && item.editor_name.id ? false : true,
            };
          });

          if (res.data.num_pages) {
            setLastPage(res.data.num_pages);
          }
          setAllStoryData(allStoryData);
        });
    }
  };

  useEffect(() => {
    setTimeout(function () {
      if (modalData) {
        const totalCount = modalData['today\'s Count'];
        setCount(totalCount);
      }
      setTimeout(function () {
        setShowElement(false);
      }, 3000);

      if (localStorage && (localStorage.videoOpt || localStorage.uploader || localStorage.editor)) {
        fetchData(currentPage, localStorage.langg);
      }
    }, 800);
  }, [currentPage, modalData, msg, pageLoad]);

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <link rel="icon" href="icon-new.ico" type="image/x-icon" />
        <link rel="shortcut icon" href="/theme/Ianstv/images/favicon.ico" type="image/x-icon" />
        <meta name="keywords" content="Ianstv news, Ianstv updates, Ianstv celebrities, Hindi cinema, Indian film industry, Ianstv gossips, Ianstv rumors, Ianstv releases, Movie reviews, Celebrity interviews" />
        <meta name="description" content="Stay up-to-date with the latest Ianstv news and updates. Get the hottest gossips, rumors, movie reviews, and exclusive celebrity interviews from the captivating world of Hindi cinema. Your one-stop destination for everything Ianstv." />
      </Helmet>
      <div className="wrapper">
        <Header />
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-10">
                  <h1 className="m-0">All Videos</h1>
                </div>
              </div>
            </div>
          </div>
          <section className="content">
            <div className="container-fluid">
              <div className="col-lg-12">
                <div className="card card-outline card-primary">
                  <div className="card-body">
                    <table className="table tabe-hover table-bordered table-compact">
                      <colgroup dangerouslySetInnerHTML={{
                        __html: `
                          <col width="5%">
                          <col width="18%">
                          <col width="30%">
                          <col width="10%">
                          <col width="7%">
                          <col width="7%">
                          <col width="7%">
                          <col width="9%">
                          <col width="7%">`
                      }} />
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Heading</th>
                          <th>Description</th>
                          <th>Add by</th>
                          <th>Editor by</th>
                          <th>Uploader by</th>
                          <th>Published Date</th>
                          <th className='text-center'>Action</th>
                          <th className='text-center'>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allStoryList.map((allSty, idx) => {
                          const status = getStatus(allSty.added_by, allSty.editor_name, allSty.uploader_name);
                          const statusClass = getStatusClass(status);

                          return (
                            <tr key={idx}>
                              <td>{idx + 1}</td>
                              <td><b>{allSty.title}</b></td>
                              <td dangerouslySetInnerHTML={{ __html: allSty.description.substring(0, 150)}}/>
                              <td>{allSty.added_by}</td>
                              <td>{allSty.editor_name && allSty.editor_name.name ? allSty.editor_name.name : ''}</td>
                              <td>{allSty.uploader_name && allSty.uploader_name.name ? allSty.uploader_name.name : ''}</td>
                              <td>{allSty.created_at}</td>
                              <td>
                                
                                <NavLink to={'/preview-video/' + allSty.id} className="btn btn-view btn-flat btn-sm manage_service" target="_blank">
                                  <i className="fas fa-eye viewedit" aria-hidden="true"></i>
                                </NavLink>

                                { (localStorage.videoOpt || localStorage.uploader)
                                && 
                                allSty.added_by
                                // && !allSty.uploader_name
                              
                                //&& allSty.editor_name && allSty.editor_name.id 
                                && 
                                  <NavLink to={'/edit-video/' + allSty.id} className="btn btn-view btn-flat btn-sm manage_service">
                                    <i className="fas fa-edit viewedit" aria-hidden="true"></i>
                                  </NavLink>
                                }

                                {localStorage && localStorage.editor && allSty.acceptAccess  &&
                                  <button className="btn-hindi-green btn" type="button" onClick={() => acceptSubmit(allSty.id)}>
                                    Accept
                                  </button>
                                }
                              </td>
                              <td><span className={`cur-status ${statusClass}`}>{status}</span></td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="pagination">
                  <ul>
                      {currentPage == 1  && <li onClick={handleStartPage} disabled={currentPage === 1}><NavLink className="prevnext disablelink" to="#">Previous</NavLink></li>}
                      {currentPage != 1  &&<li onClick={handlePrevPage} ><a href="#">Previous</a></li>}
                      {pageList.map((data, i)=>{
                      return(
                      <li key={i} onClick={()=>{
                      setCurrentPage(data)}}><a href="#" className={currentPage == data ? 'active' : ''}>{data}</a></li>
                        )
                      })}
                      <li onClick={handleNextPage}><a href="#" className="prevnext">next</a></li>
                  </ul>                                            							
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AllVideo;
