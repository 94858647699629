import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import { NavLink } from 'react-router-dom';
const AllNews = () => {
    const [newsData, setNewsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const fetchData = ()=>{
        const headers = {
            "Content-Type": "multipart/form-data",
            "Authorization": `Token ${localStorage.accessToken ? localStorage.accessToken : ''}`, // Ensure correct header
        };

        // Fetch data from the API when the component mounts
        axios.get('https://cms.iansnews.in/api/news/', { headers })
            .then(response => {
                setNewsData(response.data.results);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }
    useEffect(() => {
        // Set up headers
        fetchData()

    }, []);

    // if (loading) {
    //     return <p>Loading...</p>;
    // }

    // if (error) {
    //     return <p>Error loading data: {error.message}</p>;
    // }

    return (
        <>
    <div className="wrapper">
        <Header />
            <div className="content-wrapper">
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                            {  !loading && 
                                <div className="card recent-sales overflow-auto">
                                    <div className="card-body">
                                        <h5 className="card-title">Recent Story <span>| Today</span></h5>
                                        <div id="no-more-tables">
                                            <table className="col-md-12 table table-bordered table-striped table-condensed cf">
                                                <thead className="cf">
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Heading</th>
                                                        <th className="numeric">Created Date</th>
                                                        <th className="numeric">Created by</th>
                                                        <th className="numeric">Accepted by</th>
                                                        <th className="numeric">Status</th>
                                                        <th className="numeric">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {newsData.map((newsItem) => (
                                                        <tr key={newsItem.id}>
                                                            <td data-title="ID">{newsItem.id}</td>
                                                            <td data-title="Heading">{newsItem.title}</td>
                                                            <td data-title="Created Date" className="numeric">
                                                                {new Date(newsItem.created_at).toLocaleString()}
                                                            </td>
                                                            <td data-title="Created by" className="numeric">{newsItem.created_by }</td>
                                                            <td data-title="Accepted by" className="numeric">{newsItem.accepted_by}</td>
                                                            <td data-title="Status" className="numeric">
                                                                {newsItem.status ? (
                                                                    <span className={`badge ${getStatusClass(newsItem.status)}`}>
                                                                        {newsItem.status}
                                                                    </span>
                                                                ) : ''}
                                                            </td>
                                                            <td data-title="Action" className="numeric">
                                                        

                                                                    <NavLink to={'/preview-news/' + newsItem.id} className="btn btn-sm btn-primary mx-1">
                                                                    <i className="fas fa-edit viewedit" aria-hidden="true"></i>
                                                                    </NavLink>  

                                                            
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            {/* Pagination (You can customize pagination functionality here) */}
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination">
                                                    <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Previous">
                                                            <span aria-hidden="true">«</span>
                                                        </a>
                                                    </li>
                                                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Next">
                                                            <span aria-hidden="true">»</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                          

                                </div>
                            }
                                {loading && 
                                <div className="card-body">
                                    <div className="load-img">
                                        Loading...
                                        <div className="loader"></div>
                                    </div>  
                                </div>  
                            }
                            </div>
                        </div>
                    </div>
                </section>
            </div>

                            
            <Footer />
        </div>
        </>
    );
};

// Helper function to determine badge color based on status
const getStatusClass = (status) => {
    switch (status) {
        case 'approved':
            return 'bg-success';
        case 'on_hold':
            return 'bg-warning';
        case 'rejected':
            return 'bg-danger';
        default:
            return 'bg-secondary';
    }
};
const handleView = (id) => {
    // Replace this with actual view functionality
    console.log(`Viewing news item with ID: ${id}`);
};

// Function to handle edit button click
const handleEdit = (id) => {
    // Replace this with actual edit functionality
    console.log(`Editing news item with ID: ${id}`);
};

export default AllNews;
