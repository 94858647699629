import React from 'react';
import Home from './Home';
import ReactDOM from "react-dom";
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import Login from './Login';
import StoryEdit from './StoryEdit';
import PendingReview from './PendingReview';
import Flagged from './Flagged';
import PublishedStory from './PublishedStory'
import Mystories from './Mystories'
import Logout from './Logout'
import PublishedStoryEng from './PublishedStoryEng'
import StoryPreview from './StoryPreview'
import AllPictures from './AllPictures'
import EditPicture from './EditPicture'
import AddNewPicture from './AddNewPicture'
import PendingPictures from './PendingPictures'
import CompletedPictures from './CompletedPictures'
import AddVideo from './AddVideo'
import Test from './Test';
import AllVideo from './AllVideo'
import AllNews from './AllNews'
import JustList from './JustList'
import EditJust from './EditJust'
import EditVideo from './EditVideo'
import PreviewVideo from './PreviewVideo'
import PreviewNews from './PreviewNews'
import EditStnVideo from './EditStnVideo'
import AllVideoStn from './AllVideoStn'
import AllPhotosStn from './AllPhotosStn';
import PhotoEditStn from './PhotoEditStn';
const App = () =>{
  return(
    <>
    <BrowserRouter>
    <Switch>
    <Route exact path="/" component={Home}/>
    <Route exact path="/test" component={Test}/>
    <Route exact path="/stories-edit/:editId" component={StoryEdit}/>
    <Route exact path="/just-edit/:editId" component={EditJust}/>
    <Route exact path="/edit-video/:editId" component={EditVideo}/>
    <Route exact path="/preview-video/:editId" component={PreviewVideo}/>
    <Route exact path="/edit-stn-photos/:editId" component={PhotoEditStn}/>
    <Route exact path="/all-photos-stn" component={AllPhotosStn}/>
    <Route exact path="/preview-news/:editId" component={PreviewNews}/>
    <Route exact path="/edit-stn-video/:editId" component={EditStnVideo}/>
    <Route exact path="/all-video-stn" component={AllVideoStn}/>
    <Route exact path="/login" component={Login}/>
    <Route exact path="/all-videos" component={AllVideo}/>
    <Route exact path="/all-news" component={AllNews}/>
    <Route exact path="/all-pictures" component={AllPictures}/>
    <Route exact path="/just-in-list" component={JustList}/>
    <Route exact path="/completed-pictures" component={CompletedPictures}/>
    <Route exact path="/pending-pictures" component={PendingPictures}/>
    <Route exact path="/add-new" component={AddNewPicture}/>
    <Route exact path="/edit-pictures/:editId" component={EditPicture}/>
    <Route exact path="/logout" component={Logout}/>
    <Route exact path="/published-english" component={PublishedStoryEng}/>
    <Route exact path="/mystories" component={Mystories} />
    <Route exact path="/flagged" component={Flagged} />
    <Route exact path="/pending" component={PendingReview}/>
    <Route exact path="/published" component={PublishedStory}/>
    <Route exact path="/stories-preview/:editId" component={StoryPreview}/>
    <Route exact path="/stories-edit/:editId/:storyId" component={StoryEdit}/>
    <Route exact path="/add-video"  component={AddVideo}/>
    <Redirect to="/" />
    </Switch>
    </BrowserRouter>
    </>
  )
}

export default App;
