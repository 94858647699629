import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import {Helmet} from "react-helmet";
import { useHistory } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Multiselect from 'multiselect-react-dropdown'; // Adjust according to your Multiselect library
import API_URL from './ApiData'

import {useParams} from 'react-router-dom';


const EditJust = () => {
  const history = useHistory()
  const {editId} = useParams();



  const handleOnJustChange = event=>{
    const {name, value} =event.target;
    setInputJust({ ...inpustJust, [name]: value });

  }
  const [cattData, setCattData] = useState([]); // For storing available categories
  const [tagData, setTagData] = useState([]); // For storing selected categories
  const getCurrentDateTime = () => {
    const now = new Date();

    // Format date part as 'YYYY-MM-DDTHH:mm:ss'
    const isoString = now.toISOString().split('.')[0];

    // Get the timezone offset in hours and minutes
    const timezoneOffset = -now.getTimezoneOffset();
    const offsetHours = Math.floor(Math.abs(timezoneOffset) / 60)
      .toString()
      .padStart(2, '0');
    const offsetMinutes = (Math.abs(timezoneOffset) % 60)
      .toString()
      .padStart(2, '0');
    const offsetSign = timezoneOffset >= 0 ? '+' : '-';

    // Construct the final string with timezone offset
    const formattedDate = `${isoString}.${now
      .getMilliseconds()
      .toString()
      .padEnd(6, '0')}${offsetSign}${offsetHours}:${offsetMinutes}`;

    return formattedDate;
  };
  const [inpustJust, setInputJust] = useState({
    content:'',
    filename:'',
    updated_at:getCurrentDateTime()
  })

  const fetchData = async () => {
   
    const allStoryAPI =`${API_URL.JUST_GET}${editId}/`; 

      axios
      .get(
          allStoryAPI
      )
      .then((res) => {
          if(res && res.data){
            setInputJust({
              content:res.data.content,
              filename:res.data.filename,
              updated_at:getCurrentDateTime()
            })
            setValue(res.data.content)
          }
          
      });
  }
  useEffect(() => {
    // Fetch tags
    fetchData()
  }, []);


  const [conData, setValue] = useState('');


  const getFormattedDate = () => {
    const date = new Date();
    
    // Get individual date and time components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    // Concatenate the components into the desired format
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  };
  
  // Usage
  const formattedDate = getFormattedDate();

  const handleSubmit = async (e) => {
    e.preventDefault();

if(!inpustJust.filename){
  alert('Filename can not be blank.');
  return false;
}else if(!inpustJust.content){
  alert('Content can not be blank.');
  return false;
}

    // Construct the full API payload


    try {
      const response = await axios.put(`${API_URL.JUST_UPDATE}${editId}/update/`, inpustJust, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken : ''}`,
        },
      });
      if (response.status === 200) {
        console.log('Success:', response.data);
        history.push('/just-in-list'); // Redirect to the home page on success
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
<Helmet>
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <link rel="icon" href="icon-new.ico" type="image/x-icon" />
  <link rel="shortcut icon" href="/theme/Ianstv/images/favicon.ico" type="image/x-icon" />
  <meta data-meta-title="Ianstv.in" name="keywords" content="Ianstv news, Ianstv updates, Ianstv celebrities, Hindi cinema, Indian film industry, Ianstv gossips, Ianstv rumors, Ianstv releases, Movie reviews, Celebrity interviews" />
  <meta data-meta-title="Ianstv.in" name="description" content="Stay up-to-date with the latest Ianstv news and updates. Get the hottest gossips, rumors, movie reviews, and exclusive celebrity interviews from the captivating world of Hindi cinema. Your one-stop destination for everything Ianstv." />
</Helmet>
  <div className="wrapper">
  <Header />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-10">
                <h1 className="m-0">Edit Just In</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="col-lg-12">
              <div className="card card-outline card-primary">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="inputHeading">File Name: *</label>
                        <input
                          type="text"
                          name="filename"
                          className="form-control"
                          id="inputHeading"
                          value={inpustJust.filename}
                          onChange={handleOnJustChange}
                        />
                      </div>
                      
                    </div>

                    <div className="d-md-flex">
                        <div className="w-100">
                            <div className="form-group">
                                <label>Content *</label>
                                
                                {/* <div className='video-editor'>
                                <ReactQuill className="form-control form-control-user editShortDesc" 
                                theme="snow" 
                                type="text"
                                name="description" 
                                value={conData} 
                                onChange={setValue}
                                 
                                  
                                />
                               </div> */}

                              <textarea className="form-control form-control-user" placeholder="Content" onChange={handleOnJustChange} rows="12" name="content" value={inpustJust.content}></textarea>

                                {/* <textarea  placeholder="Description" > </textarea> */}
                            </div>
                        </div>
                    </div>

                    <button className="btn btn-primary" type="submit">Save</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  </>
  );
};

export default EditJust;
