import React,{useState, useEffect} from 'react';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import {NavLink} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import {Helmet} from "react-helmet";
import { Multiselect } from "multiselect-react-dropdown";
import "./style2.css";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom';
import API_URL from './ApiData'
import 'react-quill/dist/quill.snow.css';

const EditPicture =()=>{
    const [isLoading, setIsLoading] = useState(false);
    const [imgModal, setImgModal] = useState(false);
    const [msg, setMsg]= useState('')
    const [alertCss, setAlertCss]= useState('')
    const [tagData, setTagData] = useState();
    const [cattData, setCategoryData] = useState();
    const [uploadImg, setUploadImage] =useState({
      'title':'',
      'keywords':'',
      'caption':'',
      'source':'IANS',
      'byline':'',
      "heading":"false",
      'location':''
    })
    const sourceList = [
      "IANS",
      "Abhisek Saha(Agartala)",
      "Abhijit Addya(West Bengal)",
      "Anuwar Hazarika(Assam)",
      "Atish Naik(Goa)",
      "Arun Chandrabos(Kochi)",
      "Ajay Jalandhari(Chandigarh)",
      "Biswanath Swain(Bhubneshwar)",
      "Chandrakant Paddhane(Nagpur)",
      "Hukum Verma(Bhopal)",
      "Nisar Malik(Kahmir)",
      "Nitin Lawate(Mumbai)",
      "Pawan Sharma(Amritsar)",
      "Phool Chandra(Uttar Pradesh)",
      "Rajesh Kumar(Ranchi)",
      "Rameshwar Gaur(Haridwar)",
      "Ravishankar Vyas(Jaipur)",
      "Sanjay Baid(Dharamshala)",
      "Mh Shareef(Jodhpur)",
      "Shaukat Ahmed(Ajmer)",
      "Siddharaj Solaki(Ahmedabad)",
      "Dhananjay(Bengaluru)",
      "Indrajeet(Patna)",
      "Kuntal Chakrabarty(Kolkata)",
      "Parthibhan(Chennai)",
      "Pawan Sharma(Amritsar)",
      "Qamar Sibtain(New Delhi)",
      "Anupam Gautam(New Delhi)",
      "Wasim Sarvar(New Delhi)",
      "Snapsindia(Hyderabad)",
      "Ramana(Hyderabad)",
      "Ramesh Sharma(Noida)",
      "Prabhat Ghodke (Pune)",
      "Sudarshan Sharma (Jamshedpur)",
      "Yatish Bhanu (Nasik)",
      "Subhojit (Dhanbad)"
  ];
  
  console.log(sourceList);
  
    const [conData, setValue] = useState('');    
    const handleFileChange = (e) => {
      if(e && e.target && e.target.files[0]){
        if(e.target.files[0].type != "image/jpeg" && e.target.files[0].type != "image/jpg"){
          setMsg(`${e.target.files[0].type}  not be allowed.`)
          setAlertCss('alert-error')  
          setShowElement(true)   
          e.target.value = null;

        }
      }
      const files = e.target.files;
      setSelectedFiles([...files]); // Use the spread operator to create a new array
    };
    const [userinfo, setUserInfo] = useState({
      languages: [],
      response: [],
    });
    const [searchImg, setSearchImg] = useState({
      name:''
    });
    const [imgSearchD, setSearchImgAry] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [imgArry, setImgAry] = useState([]);
    const [pageList, setPageList] = useState([1,2,3]);
    const [lt, setLt] = useState(5);
    const {editId, storyId} = useParams();
    const [isChecked, setIsChecked] = useState(false);
    const history = useHistory();
    const handleChecked = (event) => {
      setIsChecked(event.target.checked);
    };
    const[inputData, setInputData] = useState({
      source:'IANS',
      title: '',
      location:  '',
      content: '',
      tags: '',
      topNews_category: '',
      image_caption:  '',
      top_story: false,
      keywords:  '',
      websites: '',
      states: '',
      images: null,
      slug: ''
  })
  
  const [show, setShow] = useState(false);
  const [realData, setRealtedData] = useState()
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [realtedStory, setRealtedStory] = useState(''); 
  const [lastPage, setLastPage] = useState(10);
  const handleOnMuliSelectChange = event => {
    const selectedOptions = event.target.selectedOptions;
    const selectedValues = Array.from(selectedOptions, option => option.value);
    if(event.target.name && event.target.name == 'states'){
      setInputData({ ...inputData, states: selectedValues });
    }else if(event.target.name && event.target.name == 'websites'){
      setInputData({ ...inputData, websites: selectedValues });
    }
  };
  const handleOnChange = event => {
    const { name, value } = event.target;
    setInputData({ ...inputData, [name]: value });
  };
  const handleFormOnChange = event => {
    const { name, value } = event.target;
    setPhotoForm({ ...photoForm, [name]: value });
  };
  const handleUpload = event =>{
    const { name, value } = event.target;
    setUploadImage({ ...uploadImg, [name]: value });
  }
  const handleOnImgChange = event =>{
    const { name, value } = event.target;

    setSearchImage({ ...searchImg, [name]: value })
  }
  const [currentImgPage, setImgCurrentPage] = useState(1);
  const [photoEditStatus, setPhotoEditStatus] = useState(false)
  const [photoForm, setPhotoForm] = useState({
    caption : "",
    file_name : "",
    id : null,
    image : "",
    keywords : "",
    slug : "",
    heading:"",
    title : ""
  })
  const openEditPhotoFun=(data)=>{
    setPhotoForm(data)
    setShow(true)
  }
  const [searchImage, setSearchImage] = useState('');
  const [allStoryList, SetAllStoryData] = useState([]);
  const handleImgNextPage = (curr) => {
    //if(lastPage > currentPage){
      setImgCurrentPage(curr);
      //if(currentImgPage > 1){
        getSearchImg(curr);
      // }
      // let newArry = pageList;
      // let newAddArray = pageList[pageList.length-1]+1;
      // // if(currentPage > 2){
      //   let newRemoveArray = pageList[0];
      // //
      // // }
      // newArry.shift(newRemoveArray);
      // newArry.push(newAddArray);
      //   setPageList(newArry);
    
        //}
    }
  const handleNextPage = () => {
    if(lastPage > currentPage){
      setCurrentPage(currentPage + 1);
      let newArry = pageList;
      let newAddArray = pageList[pageList.length-1]+1;
      // if(currentPage > 2){
        let newRemoveArray = pageList[0];
      //
      // }
      newArry.shift(newRemoveArray);
      newArry.push(newAddArray);
        setPageList(newArry);
    
        }
    }
  const getSearchImg = (page)=>{
    // event.preventDefault();
    if(page && page == 1){
    setImgCurrentPage(1);
    }

    if(searchImage.name){
      setShow(true);
      const imSearchAPI = `https://cms.iansnews.in/api/elastic/image/search/?query=${searchImage.name}&page=${page}`;
      axios.get(
        imSearchAPI
      ).then((res) => {
      const allImgData =
          res.data.data &&
          res.data.data.length > 0 &&
          res.data.data.map((item) => {
          return {
              id: parseInt(item.id),
              title: item.title,
              image: item.thumbnail,
              caption: item.caption,
              keywords:item.keywords 
          };
          });
      if(res && res.data && res.data.data && res.data.data.length > 0 ){
        setSearchImgAry(allImgData);
        console.log('vbb',imgSearchD);
      }

      //console.log('TMT-varun', res);
      });
    }
  
  
  }      
  const handlePrevPage = () => {
  if(currentPage > 1){
  
    setCurrentPage(currentPage - 1);
    // setCurrentPage(currentPage + 1);
    let newArry = pageList;
  
    let newAddArray = pageList[pageList.length-1];
    let newRemoveArray = pageList[0]-1;
      newArry.unshift(newRemoveArray);
    newArry.pop(newAddArray);
  setPageList(newArry);
  }
  }
  const handleImgPrevPage = (curr)=>{
    if(curr > 0){
      setImgCurrentPage(curr);
      getSearchImg(curr);
    }
  }
  const handleLastPage = () => {
  //if(requestFlag){
    setCurrentPage(lt);
    let lastArrtty = [lt-3, lt-2, lt-1]
    setPageList(lastArrtty);
  //  }
  }
  const removeImage =(id)=>{
    let filteredImages = imgArry.filter(item => item.id != id);
    const allStoryAPI =`https://cms.iansnews.in/api/images/delete/${id}`
    axios
    .post(
        allStoryAPI
    )
    .then((res) => {
    if(res && res.data && res.data.status && res.data.status==200 ){
      console.log(filteredImages,'tt')
      setImgAry(filteredImages);
     // alert();
    //  setTimeout(function() {
    //   setShowElement(false);
    //   SaveStoryData()
    // }, 2000);
     
    }
  });
  }
  const fetchData = async (page, ln) => {
    ln = ln =='ce' ? 'english' :ln;
     const allStoryAPI =`https://cms.iansnews.in/api/single-image-story?uuid=${editId}`
      axios
      .get(
          allStoryAPI
      )
      .then((res) => {
      if(res && res.data && res.data.data){
        setInputData({
          source:res.data.data.source ? res.data.data.source :'IANS',
          title:res.data.data.title ? res.data.data.title :'',
          location:  res.data.data.location ? res.data.data.location :'',
          content: res.data.data.content ? res.data.data.content :'',
          tags: '',
          topNews_category: res.data.data.topNews_category && res.data.data.topNews_category.slug ?res.data.data.topNews_category.slug : '',
          image_caption:  res.data.data.image_caption ? res.data.data.image_caption :'',
          top_story: res.data.data.top_story,
          keywords:  res.data.data.keywords ? res.data.data.keywords :'',
          summary:  res.data.data.summary ? res.data.data.summary :localStorage.userName ? localStorage.userName : '',
          websites: '',
          states: '',
          images: null,
         // loginName : loginName,
          slug: res.data.data.slug ? res.data.data.slug :''
        })
        setUploadImage({ 
          'title':res.data.data.title ? res.data.data.title :'',
          'keywords':res.data.data.keywords ? res.data.data.keywords :'',
          'caption':'',
          'source':res.data.data.source ? res.data.data.source :'IANS',
          'byline':'IANS',
          'location':res.data.data.location ? res.data.data.location :''
        })
        setValue(res.data.data.content ? res.data.data.content :'')
        setIsChecked(res.data.data.top_story);
      }
      if(res && res.data && res.data.data && res.data.data.images && res.data.data.images.length > 0 ){
        setImgAry(res.data.data.images);
      }
      if(res && res.data && res.data.data && res.data.data.tags){
        let t12 = res.data.data.tags.split(",");
        const net = t12 && t12.length > 0 && t12.map((tt, t)=>{
          return {
            id: t,
            slug: tt,
            name: tt
          }
        }) 
        setTagData(net);
      } 
      SetAllStoryData(res.data.data);
      if(res.data.data.related_story){
        setRealtedStory(res.data.data.related_story);
      }
      });
  }
  const getRealtedStoryData = (realId)=>{
    const getWebApi =`https://cms.iansnews.in/api/list-all-stories/?story_id=${realId}`; 
      axios
      .get(
        getWebApi
      )
      .then((res) => {
        if(res && res.data && res.data.data && res.data.data.length > 0){
          setRealtedData(res.data.data[0]);
        }
      });      
  }  
  const getCategoryData = ()=>{
      axios.get(API_URL.getTagData).then((res) => {
      const tagsD =
          res.data &&
          res.data.length > 0 &&
          res.data.map((item) => {
          return {
              id: item.id,
              slug: item.slug,
              name: item.name
          };
          });
          setCategoryData(tagsD);
      });      
  }
  const SaveStoryData = ()=>{

    if(!inputData.source || inputData.source == '-- Select Source --'){
      setMsg('Source can not be blank.')
      setAlertCss('alert-error')  
      setShowElement(true)   
      return false;
    }
    setMsg('')
    setIsLoading(true);
   // alert(inputData.source);
    console.log('vv', inputData)
    let nameArray = tagData && tagData.length > 0 && tagData.map(item => item.slug); // Extract 'name' property from each object
      let resultString = nameArray && nameArray.join(',').replace(/\s/g, '');
      var formData = new FormData();

      let imgArray = imgArry && imgArry.length > 0 && imgArry.map(item => item.id); // Extract 'name' property from each object
      let resultImgString = imgArray && imgArray.join(',').replace(/\s/g, '');
      formData.append('title', inputData.title);
      formData.append('summary', inputData.summary);
      formData.append('keywords', inputData.keywords);
      formData.append('location', inputData.location);
      formData.append('tags', resultString);
      formData.append('source', inputData.source);
      formData.append('topNews_category', inputData.topNews_category);
      formData.append('top_story', isChecked);
      formData.append('image_ids', resultImgString);
      
      const getStateApi =`https://cms.iansnews.in/api/images/${editId}/edit`; 
        
          axios({
            method: "post",
            url: getStateApi,
            data: formData,
            headers: { 
              "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
          }}).then((res) => {
            if(res && res.data && res.data.data && res.data.data == 'Success' && res.data.message){
              setMsg(res.data.message)
              setAlertCss('alert-success')
              setShowElement(true)
            }
              setIsLoading(false);
            }) .catch((error) => {
              if(error && error.response && error.response.data && error.response.data.detail){
                alert(error.response.data.detail);
              }
            });
              setTimeout(function() {
                setShowElement(false);
                  window.location.reload();
              }, 2000);
  }
  const SubmitStoryData = ()=>{
    setIsLoading(true);
    console.log(tagData, 'varun');
    var formData = new FormData();
    formData.append('title', inputData.title);
    const getSubmitApi = `https://cms.iansnews.in/api/images/${editId}/submit/`; 
    axios({
    method: "post",
    url: getSubmitApi,
    data: formData,
    headers: { 
      "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
    }
    }).then((res) => {
    setIsLoading(false);
    history.push('/');
    });
  }  
  const uploadImage = (e) => {
    e.preventDefault();
    const formData = new FormData();
    // Loop through selected files and upload them one by one
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append('images', selectedFiles[i]);
        formData.append("title", uploadImg.title);
        // formData.append("headline", isTopChecked ? "true": "false");
        formData.append("keywords", uploadImg.keywords);
        if(i ==0){
          formData.append("location", uploadImg.location);
          formData.append("byline", uploadImg.byline);
          formData.append("source", uploadImg.source);
        }

        
        
        formData.append("caption", uploadImg.caption);

      }
      console.log(formData, 'formDataa')
      if(!uploadImg.keywords){
        setMsg('Keywords can not be blank.')
        setAlertCss('alert-error')  
        setShowElement(true) 
      }else if(!uploadImg.caption){
        setMsg('Caption can not be blank.')
        setAlertCss('alert-error')  
        setShowElement(true) 
      }else{
        const getUploadImgApi = API_URL.fileUploadUrl;
        setIsLoading(true)
        axios({
          method: "post",
          url: getUploadImgApi,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((res) => {
            if(res && res.data && res.data.data && res.data.data.length > 0){
                const newImage =
                        res.data.data &&
                        res.data.data.length > 0 &&
                        res.data.data.map((item) => {
                        return {
                          id:item.id ? item.id : "",
                          image: item.image ? item.image : "",
                          keywords: item.keywords ? item.keywords :"",
                          title: item.title ? item.title : "",
                          caption:item.caption ? item.caption :""
                        }
                        });
              // Add the new image to the image array
              if(imgArry && imgArry.length > 0){
                // let bewwww = imgArry.push(newImage);
                // setImgAry((imgArry) => [newImage, ...imgArry]);
                const mergedArray = imgArry.concat(newImage);
  
                setImgAry(mergedArray);
              }else{
                setImgAry(newImage);
              }
              setIsLoading(false)
              setImgModal(false);
              console.log(imgArry, 'mukesh');
            }
          })
          .catch((error) => {
            setIsLoading(false)
            console.error("Error uploading image: ", error);
          });
      
      // Clear the form input fields and selected files
      // setUploadImage({
      //   title: "",
      //   keywords: "",
      //   caption: "",
      // });
      setSelectedFiles([]);
      }

  };
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isload, setIsload] = useState(false)
  const [isTopChecked, setIsTopChecked] = useState(false)
  const [isPhotoChecked, setIsPhotoChecked] = useState(false)
  const[loginName, setLoginName] = useState('')
  const handleTopChecked = (event)=>{
    setIsTopChecked(event.target.checked);
  }
  const handlePhotoChecked = (event)=>{
    setIsPhotoChecked(event.target.checked);
  }
  const PublishStoryData = ()=>{
    
    setMsg('');
    let nameArray = tagData && tagData.length > 0 && tagData.map(item => item.slug);
    let resultString = nameArray && nameArray.join(',').replace(/\s/g, '');
    if(!inputData.title){
      setMsg('News Title can not be blank.')
      setAlertCss('alert-error')
      setShowElement(true)
    }else if(!inputData.keywords){
      setMsg('Keywords can not be blank.')
      setAlertCss('alert-error')  
      setShowElement(true)   
    }else if(!inputData.source){
      setMsg('Source can not be blank.')
      setAlertCss('alert-error')  
      setShowElement(true)           
    }else if(!resultString){
      setMsg('Category can not be blank.')
      setAlertCss('alert-error')  
      setShowElement(true)   
    }else{
      setIsload(true)
      const getpublishApi = `https://cms.iansnews.in/api/images/${editId}/publish/`;
    axios({
      method: "post",
      url: getpublishApi,
      headers: { 
        "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
    }}).then((res) => {
      //setIsLoading(false)
     // history.push('/all-pictures')
      WebPublishStoryData()
    })
  }
} 
  const updateImageContent = () =>{
      setMsg('')
      setIsLoading(true);
        var formData = new FormData();
        formData.append('caption', photoForm.caption);
        formData.append('keywords', photoForm.keywords);
        formData.append('headline',isPhotoChecked ? "true" :"false")
        const getStateApi =`https://cms.iansnews.in/api/images/${photoForm.id}/post/`; 
  axios({
    method: "put",
    url: getStateApi,
    data: formData,
    headers: { 
      "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,

  }}).then((res) => {
    SaveStoryData()
    if(res && res.data && res.data.data && res.data.data == 'Success' && res.data.message){
      setMsg(res.data.message)
      setAlertCss('alert-success')
      setShowElement(true)
    }
    console.log(res,'cccccccccc');
    setIsLoading(false);
  }) .catch((error) => {
    if(error && error.response && error.response.data && error.response.data.detail){
      alert(error.response.data.detail);
    }
    console.error("Error uploading image: ", error);
  });
  setTimeout(function() {
    setShowElement(false);
      window.location.reload();
  }, 2000);
      
  }  
  const WebPublishStoryData = ()=>{
  const getwebpublishApi = `https://cms.iansnews.in/api/images/${editId}/webpublish/`;
  axios({
    method: "post",
    url: getwebpublishApi,
    headers: { 
    Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
  },
  }).then((res) => {
    if(res.data && res.data.status && res.data.status == 200){
      setIsload(false)
      history.push('/all-pictures')
    }
  });
  }  
  const [showElement, setShowElement] = useState(false);
  useEffect(() => {
    if(localStorage && localStorage.langg  && localStorage.langg=='mt'){
      fetchData(currentPage, localStorage.langg);
      setLoginName(localStorage.userName ? localStorage.userName : '')
    }
    setTimeout(function() {
      setShowElement(false);
      }, 3000);
    getCategoryData();
    if(realtedStory){
      getRealtedStoryData(realtedStory);
    }
  }, [currentPage, realtedStory, msg]);
       
    return(
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <link rel="icon" href="icon-new.ico" type="image/x-icon" />
                <link rel="shortcut icon" href="/theme/Ianstv/images/favicon.ico" type="image/x-icon" />
                
                <meta data-meta-title="Ianstv.in" name="keywords" content="Ianstv news, Ianstv updates, Ianstv celebrities, Hindi cinema, Indian film industry, Ianstv gossips, Ianstv rumors, Ianstv releases, Movie reviews, Celebrity interviews" />
                <meta data-meta-title="Ianstv.in" name="description" content="Stay up-to-date with the latest Ianstv news and updates. Get the hottest gossips, rumors, movie reviews, and exclusive celebrity interviews from the captivating world of Hindi cinema. Your one-stop destination for everything Ianstv." />
            </Helmet>
            <div className="wrapper">
              <Header />

                <div className="content-wrapper">
                  <div className="content-header">
                    <div className="container-fluid">
                      <div className="row mb-2">
                        {showElement &&       
                        <div className="alert-m">
                          <p className={alertCss}>{msg}</p>
                        </div>
                        }
                        <div className="col-sm-10">
                          <h1 className="m-0">Edit Event</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <section className="content">
                    <div className="container-fluid">
                      <div className="col-lg-12">
                        <div className="card card-outline card-primary">
                          <div className="card-body">
{  !isload &&                  
                            <div className="col-md-12">																	
                              <div className="form-group">
                                <label htmlFor="title">Event Title</label>
                                <input type="text" onChange={handleOnChange} name="title" className="form-control" id="inputHeading" value={inputData.title}/>
                              </div>
                              <div className="form-group">
                                <label htmlFor="keyword">Keyword</label>
                                <input type="text" className="form-control" onChange={handleOnChange} name="keywords" value={inputData.keywords}/>
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <label htmlFor="location">Location</label>
                                  <input type="text" className="form-control" onChange={handleOnChange} name="location" value={inputData.location}/>
                                </div>
                                <div className="form-group col-md-6">
                                  <label htmlFor="initial">Initial</label>
                                  <input type="text" className="form-control" onChange={handleOnChange} name="summary" value={inputData.summary} readOnly/>
                                </div>
                                <div className="form-group col-md-12">
                                  <label htmlFor="source">Source</label>
                                  <select className="form-control" name="source" onChange={handleOnChange} value={inputData.source} required>
                                    <option selected="">-- Select Source --</option>
                                    {sourceList && sourceList.length > 0 && sourceList.map((itemSour, sou)=>{
                                      return(
                                        <option value={itemSour} key={sou}>{itemSour}</option>
                                      )
                                    })}
                                  </select>
                                 
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="form-check">
                                <input
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleChecked}
                                className="form-check-input"
                              />
                                  <label className="form-check-label" htmlFor="gridCheck">
                                  Top Story
                                  </label>
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <label htmlFor="tags">Category</label>
                                  <Multiselect
                                  options={cattData}
                                  displayValue="slug"
                                  selectedValues={tagData}
                                  onSelect={(selectedList) => setTagData(selectedList)}
                                  onRemove={(selectedList) => setTagData(selectedList)}
                                />                                  
                                </div>
                                {/* <div className="form-group col-md-6">
                                  <label htmlFor="topCat">Top News Category</label>
                                      <select className="form-control" name="topNews_category" onChange={handleOnChange} value={inputData.topNews_category}>
                                    <option selected="">-- Select Top News Category --</option>
                                    {cattData && cattData.length > 0 && cattData.map((catt, c)=>{
                                      return(
                                        <option value={catt.slug} key={c}>{catt.name}</option>
                                      )
                                    })}
                                      </select>
                                </div> */}
                              </div>
                              <div className="accordion" id="morePhoto">
                                <button className="addimg-btn btn btn-primary" type="button" onClick={()=>{
                                    setImgModal(!imgModal)
                                  }}>
                                + Add Images
                                </button>
                              {isLoading     && 
                                <div className="load-img">
                                  Uploading...
                                  <div className="loader"></div>
                                </div>  
                              }	
                                <div className="accordion" id="morePhoto">
                          {imgModal &&
                            <div>
                              <div className="card card-body">
                              <form encType="multipart/form-data" className="border p-2 mb-2 rounded">
                                    <div className="form-row">
                                      
                                    <div className="form-group col-md-2">
                                      <label htmlFor="files">Photos</label>
                                      <input
                                        type="file"
                                        onChange={handleFileChange}
                                        name="image"
                                        multiple
                                        required
                                        accept="image/jpeg, image/jpg"
                                      />
                                    </div>
                                    {/* <div className="form-group col-md-4">
                                    <input
                                      type="checkbox"
                                      checked={isTopChecked}
                                      onChange={handleTopChecked}
                                    />
                                     <label htmlFor="imageCaption"> <span>&nbsp;</span> Top Image</label>
                                  </div>  */}
                                    <div className="form-group col-md-10">
                                      <label htmlFor="imageKeyword">Image Keyword</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="keywords"
                                        onChange={handleUpload}
                                        value={uploadImg.keywords}
                                        placeholder="Enter your image keywords"
                                        required
                                      />
                                    </div>
                                    <div className="form-group col-md-12">
                                      <label htmlFor="imageCaption">Image Caption</label>
                                      <textarea 
                                        className="form-control"
                                        name="caption"
                                        onChange={handleUpload}
                                        value={uploadImg.caption}
                                        placeholder="Enter caption"
                                      />
                                    </div>
                                    <div className="form-group col-md-12">
                                      <label htmlFor="imageKeyword">Title</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="title"
                                        onChange={handleUpload}
                                        value={uploadImg.title}
                                        placeholder="Enter your image title"
                                        required
                                        readOnly/>
                                    </div> 
                                    <div className="form-group col-md-4">
                                      <label htmlFor="imageKeyword">Byline</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="byline"
                                        onChange={handleUpload}
                                        value={uploadImg.byline}
                                        placeholder="Enter your image byline"
                                        required readOnly
                                      />
                                    </div>   
                                    <div className="form-group col-md-4">
                                      <label htmlFor="imageKeyword">Source</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="source"
                                        onChange={handleUpload}
                                        value={uploadImg.source}
                                        placeholder="Enter your image source"
                                        required readOnly
                                      />
                                    </div> 
                                    <div className="form-group col-md-4">
                                      <label htmlFor="imageKeyword">Location</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="location"
                                        onChange={handleUpload}
                                        value={uploadImg.location}
                                        placeholder="Enter your image location"
                                        required readOnly
                                      
                                      />
                                    </div>                                                                                                          

                                  </div>
                                  
                                  <div className="form-row">
                                    <button className="btn btn-success" onClick={uploadImage}>
                                      Add
                                    </button>
                                    <button className="btn btn-secondary" type="button">
                                      Cancel
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>}  
                            </div>
                              </div>
                              
                              <ul className="pending-pic edit-event-pic">
                              {imgArry && imgArry.length > 0 && imgArry.map((imgg, im)=>{
                               let trrr= imgg.image && imgg.image.split("/images/").length > 0 ? imgg.image.split("/images/")[1] :'';
                               let imggg =imgg.image ?  imgg.image.replace("/images/", "/thumbnails_watermark/"):''
                                    return(
                                  <li key={im}>
                                    <img src={imggg}/>
                                    <div className="editpho-icon">
                                      <NavLink to={'#'} onClick={()=>{
                                              removeImage(imgg.id)
                                      }}>
                                        <i className="fa fa-trash" aria-hidden="true"></i>
                                      </NavLink>
                                      <NavLink to={'#'} onClick={()=>{
                                              openEditPhotoFun(imgg)
                                      }}>
                                        <i className="fa fa-edit" aria-hidden="true"></i>
                                      </NavLink>
                                    </div>  
                                    <p className='img-name'>{trrr}</p> 
                                    <p>{imgg.caption}</p>
                                  </li>                            
                                     
                                    )
                                  })} 

                              </ul>
                            </div>
                            }
                            <div className="col-md-12">
                            {isload && 
                <div className="load-img">
                Uploading...
                <div className="loader"></div>
              </div>
                }
                            </div>
                            
                          </div>
                        </div>
                        <div className="col-md-12 text-center mt-4 pub-btn-fixed">
                          <button className="btn btn-secondary savebtn" type="button" onClick={SaveStoryData}>Save</button>
                          <button className="btn btn-warning" type="button" onClick = {SubmitStoryData}>Submit Review</button>
                          <button className="btn btn-primary" type="button"onClick = {PublishStoryData} >Publish</button>
                        </div>
                      </div>        
                    </div>
                  </section>
                </div>
                

              <Footer />
            </div>
            <Modal show={show} onHide={handleClose} className='medit-pic'>
              <Modal.Header>Image Edit
                <button onClick={handleClose} className="close" role="button" tabIndex="0" >&times;</button>
              </Modal.Header>
              <Modal.Body>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label for="editTitle">Caption</label>
                        <textarea type="text" className="form-control"  placeholder="Caption" onChange={handleFormOnChange} name="caption" value={photoForm.caption}/>
                      </div>
                      <div className="form-group">
                        <label for="editKeyword">keywords</label>
                        <input type="text" className="form-control"  placeholder="Keywords" onChange={handleFormOnChange} value={photoForm.keywords} name="keywords"/>
                      </div>
                      <div className="form-group">
                        <label for="editKeyword">Top Image</label><span>&nbsp;</span>
                        <input
                          type="checkbox"
                          checked={isPhotoChecked}
                          onChange={handlePhotoChecked}
                        />
                                       
                      </div>
                    </div>
                    <div className="col-md-12 text-center mt-4">
                      <button type="submit" onClick = {updateImageContent} className="btn btn-primary">Submit</button>
                    </div>
                    </div>
                  </div>
              </Modal.Body>
            </Modal>
        </>
    )
}

export default EditPicture