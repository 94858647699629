import React,{useState, useEffect} from 'react';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import {NavLink, useHistory} from 'react-router-dom'
import {Helmet} from "react-helmet";
import moment from 'moment';
import API_URL from './ApiData'



const PublishedStoryEng =()=>{
    const history = useHistory();
    const [showElement, setShowElement] = useState(false);
    const [alertCss, setAlertCss]= useState('')
    const [msg, setMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [userData, setUserData] = useState();
    const [pageList, setPageList] = useState([1,2,3]);
    const [lt, setLt] = useState(5);
    const [lastPage, setLastPage] = useState(10);
    const [publishedObj, setPublishedByUser] = useState();
    const [userinfo, setUserInfo] = useState({
      languages: [],
      response: [],
    });
    const [assignUser, setAssignUser] = useState({
      userId :null
    })
    const [search, setSearch] = useState('');
    const [allStoryList, SetAllStoryData] = useState([]);
    const handleNextPage = () => {
      if(lastPage > currentPage){
        setCurrentPage(currentPage + 1);
        let newArry = pageList;
        let newAddArray = pageList[pageList.length-1]+1;
        // if(currentPage > 2){
          let newRemoveArray = pageList[0];
        //
        // }
        newArry.shift(newRemoveArray);
        newArry.push(newAddArray);
          setPageList(newArry);
      
         }
      
      }
      const handleOnChange = event => {
        const { name, value } = event.target;
        setAssignUser({ ...assignUser, [name]: value });
      };      
      const handleAssign = (e) => {
        // Destructuring
        const { value, checked } = e.target;
        const { languages } = userinfo;
        console.log(`${value} is ${checked}`);
        if (checked) {
          setUserInfo({
            languages: [...languages, value],
            response: [...languages, value],
          });
        }
      
        // Case 2  : The user unchecks the box
        else {
          setUserInfo({
            languages: languages.filter((e) => e !== value),
            response: languages.filter((e) => e !== value),
          });
        }
        // }
        // Case 1 : The user checks the box
      };      
      const handlePrevPage = () => {
      if(currentPage > 1){
        setCurrentPage(currentPage - 1);
        // setCurrentPage(currentPage + 1);
        let newArry = pageList;
        let newAddArray = pageList[pageList.length-1];
        let newRemoveArray = pageList[0]-1;
          newArry.unshift(newRemoveArray);
        newArry.pop(newAddArray);
      setPageList(newArry);
      }
      }
      const handleLastPage = () => {
      //if(requestFlag){
        setCurrentPage(lt);
        let lastArrtty = [lt-3, lt-2, lt-1]
        setPageList(lastArrtty);
      //  }
      }
      const handleStartPage = () => {
      // if(requestFlag){
        setCurrentPage(1);
        let lastArrtty = [1, 2, 3]
        setPageList(lastArrtty);
      //  }
      }
      const handleSetPage = (page) => {
      //  if(requestFlag){
        setCurrentPage(page);
      //}
      }
      const postAssignUser = () => {
        if (userinfo.response && userinfo.response.length === 1) {
          fetch(API_URL.createAndAssignStory, {
            method: 'POST',
            body: JSON.stringify({
              user_id: assignUser.userId,
              uuid: userinfo.response[0],
            }),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
              Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken : ''}`,
            },
          }).then((res) => {
            if(res && res.status==200){
              setTimeout
              (window.location.href = "/published-english", 5000);
              setAssignUser({
                userId:null
              })
              setUserInfo({
                languages: [],
                response: [],
              })
              setMsg('Story Assign successfully.')
              setAlertCss('alert-success')
              setShowElement(true)
            }
    
            //fetchData(currentPage, localStorage.langg);
            });
          }else if(userinfo.response && userinfo.response.length > 1){
            setMsg('Please select only one story.')
            setAlertCss('alert-error')
            setShowElement(true)
              //alert('Please select only one story.');
          }else{
            setMsg('Please select one story minimum.')
            setAlertCss('alert-error')
            setShowElement(true)
          }
          };
    const fetchData = async (page , ln) => {
      ln = ln =='ce' ? 'english' :ln; 
      const allStoryAPI = API_URL.Story_URL+`english&partial_status=PUBLISHED&page=${currentPage}&query=${search}`; 
        axios
        .get(
            allStoryAPI
        )
        .then((res) => {
        const allStoryData =
            res.data.data &&
            res.data.data.length > 0 &&
            res.data.data.map((item) => {
            return {
                id: item.id,
                title: item.title,
                current_status: item.current_status,
                slug:'/'+item.slug,
                hindi_converted_by:item.hindi_converted_by,
                uuid:item.uuid,
                user_edited_by : item.user_edited_by,
                created_at:item.created_at,
            };
            });
            if( res.data.data &&
              res.data.data.length > 0){
                //setPublishedByUser(res.data.data.user_edited_by);
              }
        SetAllStoryData(allStoryData);
        });
    }
    const handleOnSearch = event => {
      const { name, value } = event.target;
      setSearch(value);
    };
    const postHindiStory = (uuid)=>{
      const apiUrl =`https://cms.iansnews.in/api/stories/create/hindi/`; 
      var formData = new FormData();
      formData.append('uuid', uuid);
      axios({
        method: "post",
        url: apiUrl,
        data: formData,
        headers: { 
          "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
      
      },
        
      }).then((res) => {
        if(res && res.data && res.data.data &&res.data.data.uuid){
          history.push('/stories-edit/'+res.data.data.uuid);
          console.log('resss',res);
        }
         // setStateData(stateD);
      }); 
      setTimeout(function() {
          window.location.reload();

      }, 2000);
    }
    const searchData =()=>{
      //alert(search)
      fetchData(currentPage, localStorage.langg);
    }
    const getUserData = (ln)=>{
      const setUserApi = API_URL.getUserData+ln; 
        axios
        .get(
          setUserApi
        )
        .then((res) => {
        const stateD =
            res.data.data &&
            res.data.data.length > 0 &&
            res.data.data.map((item) => {
            return {
                id: item.id,
                groups: item.groups,
                name: item.name,
                email: item.email,
                username: item.username
            };
            });
            setUserData(stateD);
        });      
    }   
    useEffect(() => {
      if(localStorage && localStorage.langg){
        // setLangDefault(localStorage.langg);
        if(localStorage.langg  && localStorage.langg=='hindi'){
          fetchData(currentPage, localStorage.langg);
          getUserData(localStorage.langg);
        }
      }

      }, [currentPage]);
       
    return(
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <link rel="icon" href="icon-new.ico" type="image/x-icon" />
                <link rel="shortcut icon" href="/theme/Ianstv/images/favicon.ico" type="image/x-icon" />
                <meta data-meta-title="Ianstv.in" name="keywords" content="Ianstv news, Ianstv updates, Ianstv celebrities, Hindi cinema, Indian film industry, Ianstv gossips, Ianstv rumors, Ianstv releases, Movie reviews, Celebrity interviews" />
                <meta data-meta-title="Ianstv.in" name="description" content="Stay up-to-date with the latest Ianstv news and updates. Get the hottest gossips, rumors, movie reviews, and exclusive celebrity interviews from the captivating world of Hindi cinema. Your one-stop destination for everything Ianstv." />
            </Helmet>
            <div className="wrapper">
            <Header />
              <div className="content-wrapper">
                <div className="content-header">
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-sm-10">
                        <h1 className="m-0">Published Stories</h1>
                      </div>
                    </div>
                  </div>
                </div>
                <section className="content">
                  <div className="container-fluid">
                    <div className="col-lg-12">
                      <div className="card card-outline card-primary">
                        <div className="card-body">

                        <div className="row mb-2">

                            <div className="col-sm-8">
                            <select className="assign-s" name="userId" value={assignUser.userId} onChange={handleOnChange}>
                              <option value="0" className="assign-hed" >Assign To:</option>
                              {userData && userData.length > 0 && userData.map((usee, u)=>{
                                return(
                                   <option value={usee.id} key={u} >{usee.username}</option>
                                )
                              })}
                            </select>
                              <button className="btn btn-primary btn-Assign" onClick={postAssignUser} type="button">Assign</button>
                            </div>
                            <div className="col-sm-4">
                            <input type="text" className="search-s" name="search" value={search} onChange={handleOnSearch} />
                              <button className="btn btn-primary btn-Assign" onClick={searchData} type="button">Search</button> 
                            </div>
                          </div>
                        <div className="col-md-12 mt-4">
                            
 
                              <div className=''>
                                                              
                              </div>                      
                        </div>
                         
                          <table className="table tabe-hover table-bordered table-compact" id="">
                            <colgroup dangerouslySetInnerHTML={{
                                __html: `
                                <col width="7%">
                                <col width="3%">
                                <col width="40%">
                                <col width="10%">
                                <col width="10%">
                                <col width="8%">
                                <col width="12%">
                                <col width="10%">
                                `
                              }}/>
                            <thead>
                              <tr>
                                <th className="text-center">ID</th>
                                <th>Assign</th>
                                <th>Heading</th>
                                <th>Published By</th>
                                <th>Hindi User</th>
                                <th>Current Status</th>
                                <th>Last Modified</th>
                                <th className='text-center'>Action</th>
                              </tr>
                            </thead>
                            <tbody>		
                              {
                                allStoryList && allStoryList.length > 0 && allStoryList.map((allSty, c)=>{
                              
                                  return(
                                    <tr key = {c}>
                                      <td className="text-center">{allSty.id}</td>
                                      <td className="text-center">
                                    {!allSty.hindi_converted_by || !allSty.hindi_converted_by.username &&
                                        <label className="container">
                                        <input type="checkbox" onChange={handleAssign}  name="assignnn" value={allSty.uuid}/>
                                          <span className="checkmark"></span>
                                        </label>}
                                      </td>
                                      <td>{allSty.title}</td>
                                      <td>{allSty.user_edited_by.username}</td>
                                      <td>{allSty.hindi_converted_by.username}</td>
                                      <td><span className={allSty.current_status == 'PENDING' ? 'cur-status pending': allSty.current_status == 'CREATED' ? 'cur-status new_mail' : allSty.current_status == 'ASSIGNED' ? 'cur-status assigned' : allSty.current_status == 'PUBLISHED' ? 'cur-status published' : 'cur-status being_edited'}>{allSty.current_status}</span></td>
                                      <td>{allSty.created_at}</td>                                 
                                      <td className="text-center">
                                        {!allSty.hindi_converted_by || !allSty.hindi_converted_by.username && <button className="btn-hindi-green btn " type="button" 
                                          onClick={() => {
                                            postHindiStory(allSty.uuid);
                                          }}
                                        > <i className="fas fa-edit"></i> Hindi
                                        </button>}
                                        <NavLink to= {'/stories-preview/'+allSty.uuid} className="btn btn-view btn-flat btn-sm manage_service" target="_blank"><i className="fa fa-eye viewedit" aria-hidden="true"></i></NavLink>
                                      </td>                              
                                    </tr>
                                  )
                                })
                              }										
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="pagination">
                        <ul>
                            {currentPage == 1  && <li onClick={handleStartPage} disabled={currentPage === 1}><NavLink className="prevnext disablelink" to="#">Previous</NavLink></li>}
                            {currentPage != 1  &&<li onClick={handlePrevPage} ><a href="#">Previous</a></li>}
                            {pageList.map((data, i)=>{
                            return(
                            <li key={i} onClick={()=>{
                            setCurrentPage(data)}}><a href="#" className={currentPage == data ? 'active' : ''}>{data}</a></li>
                              )
                            })}
                            <li onClick={handleNextPage}><a href="#" className="prevnext">next</a></li>
                        </ul>                                            							
                    </div>                    
                  </div>
                </section>

                <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Add News</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div className="modal-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="title">Title</label>
                          <input type="text" className="form-control" id="title" placeholder="Enter your title" />
                          <div data-title-error="" className="form-text text-danger error" style={{display:'none'}}>Enter Your Title</div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="shortDesc">Summary</label>
                          <textarea className="form-control form-control-user" placeholder="Summary" rows="6" id="shortDesc" required="" style={{height: '100px'}}></textarea>
                        </div>
                      </div>
                    </div>
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-primary">Save</button>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    </div>
                  </div>
                  </div>
                </div>
      
              </div>
              <Footer />
            </div>
        </>
    )
}

export default PublishedStoryEng