import React,{useEffect} from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';

const LogOut = ()=>{
    const history = useHistory();
    const location = useLocation();
    const { from } = location.state || { from: { pathname: '/login' } };
      useEffect(() => {
        localStorage.clear();
        if(!localStorage || !localStorage.accessToken){
            history.push('/login');
        }
        <Redirect to={from} />
      }, []);
        return(
        <>
        </>
    )
}
export default LogOut