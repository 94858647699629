import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import {Helmet} from "react-helmet";
import { useHistory, NavLink } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Multiselect from 'multiselect-react-dropdown'; // Adjust according to your Multiselect library
import API_URL from './ApiData'
import {useParams} from 'react-router-dom';
import copy from "copy-to-clipboard"
import { format, parseISO } from 'date-fns'
const EditStnVideo = () => {
  const history = useHistory()
  const [isAccept, setIsAccept] = useState(false)
  const {editId} = useParams();
  const handleChange = event=>{
    const {name, value} =event.target;
    setInputData({ ...inputData, [name]: value });

  }

  const [readOn, setReadOn] = useState(false)
  const [isVideoRow, setIsVideoRow] = useState(false)
  const [conData, setValue] = useState('');
  const [inputData, setInputData] = useState({
    id: null,
    title: '',
    description: '',
    videos: [],
    status: "",
    language: '',
    created_by: "",
    accepted_by: "",
    remarks: "",
    created_at: ""
  })
  const formatDate = (timestamp)=>{
    const date = parseISO(timestamp)
    return format(date, 'MMMM dd, yyyy h:mm a')
}
  const fetchData = async () => {
    setIsload(true)
      axios(
         {method: "get",
          url: `${API_URL.CREATE_VIDEO}${editId}/`,
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Token ${localStorage.accessToken ? localStorage.accessToken : ''}`, // Ensure correct header
          },
        
        })
      .then((res) => {
          if(res && res.data){
            setInputData({
              title: res.data.title,
              description: res.data.description,
              status: res.data.status,
              language: res.data.language,
              created_by: res.data.created_by,
              accepted_by:res.data.accepted_by,
              remarks: res.data.remarks,
              videos:res.data.videos,
              created_at: res.data.created_at ? formatDate(res.data.created_at): '',
            })
            setValue(res.data.description)
            if(res.data.videos && res.data.videos.length > 0){
              setImgAry(res.data.videos)
            }
            if(res.data.created_by && localStorage && localStorage.length > 0 && localStorage.userName && res.data.created_by != localStorage.userName){
              setIsAccept(true)
            }
            if(res.data.remarks){
              setRemoveCont(res.data.remarks)
            }
            setIsload(false)
          }
      })
      //setIsload(false)
  }


  // Usage




  const handleSubmit =  (type) => {
   // e.preventDefault();
   setIsload(true)
    if(!inputData.title){
      alert('Title can not be blank.');
      setIsload(false)
      return false;
    }else if(!inputData.description){
      alert('Description can not be blank.');
      setIsload(false)
      return false;
    }
const NewAPArry = imgArry.map(video => video.id);

    // let tempdatac = {
    //   id: inputData.id,
    //   title: inputData.title,
    //   description: conData,
    //   video_ids: NewAPArry,
    //   videos:imgArry,
    //   status: inputData.status,
    //   language: inputData.language,
    //   created_by: inputData.created_by,
    //   accepted_by: inputData.accepted_by,
    //   remarks: inputData.remarks,
    //   created_at: inputData.created_at,
    // }

    let tempdata = {
      id: inputData.id,
      title: inputData.title,
      description: conData,
      video_ids: NewAPArry,
      videos:imgArry,
      status: 'on_hold',
      language: inputData.language,
      created_by: inputData.created_by,
      // accepted_by: inputData.accepted_by,
      remarks: removeCont,
      // created_at: inputData.created_at,
    }
    let tempAccptdata = {
      id: inputData.id,
      title: inputData.title,
      description: conData,
      video_ids: NewAPArry,
      videos:imgArry,
      status: 'accepted',
      language: inputData.language,
      created_by: inputData.created_by,
      accepted_by: localStorage && localStorage.length > 0 && localStorage.userName ? localStorage.userName : '',
      remarks: removeCont,
      // created_at: inputData.created_at,
    }
    let tempRejectdata = {
      id: inputData.id,
      title: inputData.title,
      description: conData,
      video_ids: NewAPArry,
      videos:imgArry,
      status: 'rejected',
      language: inputData.language,
      created_by: inputData.created_by,
      accepted_by: localStorage && localStorage.length > 0 && localStorage.userName ? localStorage.userName : '',
      remarks: removeCont,
      // created_at: inputData.created_at,
    }  
    axios({
      method: "put",
      url: `${API_URL.CREATE_VIDEO}${editId}/`,
      data: type && type == 1 ? tempAccptdata : type == 2 ? tempRejectdata : tempdata,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.accessToken ? localStorage.accessToken : ''}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        console.log('Success:', response.data);
        setIsload(false)
       history.push('/all-video-stn'); // Redirect to the home page on success
      }
    })
  }
  const [isload, setIsload] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [imgModal, setImgModal] = useState(false);
  const [msg, setMsg]= useState('')
  const [imgArry, setImgAry] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleFileChange = (e) => {
    if(e && e.target && e.target.files[0]){
      if(e.target.files[0].type != "video/mp4" && e.target.files[0].type != "video/mov"){
        alert(`${e.target.files[0].type}  not be allowed.`)
        e.target.value = null;

      }
    }
    const files = e.target.files;
    setSelectedFiles([...files]); // Use the spread operator to create a new array
    
  };

const uploadVideo = (e) => {
    e.preventDefault();
    const formData = new FormData();
    // Append each selected file as 'video' to the formData
    selectedFiles.forEach((file) => {
        formData.append('video', file);
    });
    console.log(formData, 'formDataa');

    setIsLoading(true);
    axios({
        method: "post",
        url: `${API_URL.CREATE_VIDEO}upload-video/`,
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${localStorage.accessToken || ''}`,
        },
    })
    .then((res) => {
        if (res?.data?.uploaded_videos?.length > 0) {
            const newVideos = res.data.uploaded_videos.map((item) => ({
                id: item.id || "",
                video: item.video_url || "",  // Updated to match video URL field
            }));
            // Merge new videos with existing array or set new array
            setImgAry(imgArry && imgArry.length > 0 ? imgArry.concat(newVideos) : newVideos);
            handleSubmit(3);
            setIsLoading(false);
            setImgModal(false);
            console.log(imgArry, 'videos');
        }
    })
    .catch((error) => {
        setIsLoading(false);
        console.error("Error uploading video: ", error);
    });

    // Clear the form input fields and selected files
    setSelectedFiles([editId]);
};
const [removeCont, setRemoveCont] = useState('')
const removeImg = (id)=>{
 // alert(id)
  const newImgArry = imgArry.filter(item => item.id !== id)
  if(!removeCont){
    setRemoveCont('Deleted id -'+id)
  }else{
    setRemoveCont(removeCont + ', '+id)
  }
  
  setImgAry(newImgArry)
}
  useEffect(() => {
    // Fetch tags
    // if(localStorage && localStorage.length > 0 && (localStorage.videoOpt || localStorage.editor)){
    //   setReadOn(false)
    // }
    fetchData()
 
  }, [editId]);
  return (
    <>
<Helmet>
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <link rel="icon" href="icon-new.ico" type="image/x-icon" />
  <link rel="shortcut icon" href="/theme/Ianstv/images/favicon.ico" type="image/x-icon" />
  <meta data-meta-title="Ianstv.in" name="keywords" content="Ianstv news, Ianstv updates, Ianstv celebrities, Hindi cinema, Indian film industry, Ianstv gossips, Ianstv rumors, Ianstv releases, Movie reviews, Celebrity interviews" />
  <meta data-meta-title="Ianstv.in" name="description" content="Stay up-to-date with the latest Ianstv news and updates. Get the hottest gossips, rumors, movie reviews, and exclusive celebrity interviews from the captivating world of Hindi cinema. Your one-stop destination for everything Ianstv." />
</Helmet>
  <div className="wrapper">
  <Header />
  <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-10">
                <h1 className="m-0">Edit News</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="col-lg-12">
            {!isload &&
              <div className="card card-outline card-primary">
                <div className="card-body">
                  <form>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="inputHeading">Title: *</label>
                        <input
                          type="text"
                          name="title"
                          className="form-control"
                          id="inputHeading"
                          value={inputData.title}
                          onChange={handleChange}
                          readOnly ={readOn}
                        />
                      </div>
                    </div>

                    <div className="d-md-flex">
                        <div className="w-100">
                            <div className="form-group">
                                <label>Main Content *</label>
                                <div className='video-editor'>
                                  <ReactQuill className="form-control form-control-user editShortDesc" 
                                  theme="snow" 
                                  type="text"
                                  name="content" 
                                  value={conData} 
                                  onChange={setValue}
                                  readOnly ={readOn}
                                  />
                                </div>                                
                            </div>
                        </div>
                    </div>
                  <div className="form-row">
                  <div className="form-group col-md-6">
                        <label htmlFor="inputSlug">Created By *</label>
                        <input
                          type="text"
                          className="form-control"
                          name="created_by"
                          value={localStorage.userName ? localStorage.userName : ''}
                          onChange={handleChange}
                          rows="6"
                          readOnly ={readOn}
                        />
                      </div>  
                      <div className="form-group col-md-6">
                        <label htmlFor="inputLanguage">Language *</label>
                          <select
                            className="form-control"
                            name="language"
                            value={inputData.language}
                            onChange={handleChange}
                          >
                            <option value="">-- Select Language --</option>
                            <option value="en">English</option>
                            <option value="hi">Hindi</option>
                          </select>
                      </div>                    

                    </div>     
                                                                          
                <section className="content">
                    <div className="container-fluid">
                      <div className="col-lg-12">
                        <div className="card card-outline card-primary">
                          <div className="card-body">
                    {  !isload &&                  
                            <div className="col-md-12">																	
                              <div className="accordion" id="morePhoto">
                                <button className="addimg-btn btn btn-primary" type="button" onClick={()=>{
                                    setImgModal(!imgModal)
                                  }}>
                                + Add Videos
                                </button>
                              {isLoading     && 
                                <div className="load-img">
                                  Uploading...
                                  <div className="loader"></div>
                                </div>  
                              }	
                                <div className="accordion" id="morePhoto">
                          {imgModal &&
                            <div>
                              <div className="card card-body">
                              <form encType="multipart/form-data" className="border p-2 mb-2 rounded">
                                    <div className="form-row">
                                    <div className="form-group col-md-2">
                                      <label htmlFor="files">Videos</label>
                                      <input
                                        type="file"
                                        onChange={handleFileChange}
                                        name="video"
                                        multiple
                                        required
                                        accept="video/mp4"
                                      />
                                    </div>
                                  </div>
                                  
                                  <div className="form-row">
                                    <button className="btn btn-success" onClick={uploadVideo}>
                                      Add
                                    </button>
                                    <button className="btn btn-secondary" type="button">
                                      Cancel
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>}  
                            </div>
                              </div>
                              
                              <ul className="pending-pic edit-event-pic edit-video">
                              {imgArry && imgArry.length > 0 && imgArry.map((imgg, im)=>{
                               let imggg =imgg.video ?  imgg.video:''
                                    return(
                                  <li key={im}>
                                    <video controls src={imggg}/>
                                    <div className="editpho-icon">
                                      <NavLink to={'#'} onClick={() => removeImg(imgg.id)}>
                                        <i className="fa fa-trash" aria-hidden="true"></i>
                                      </NavLink>
                                      <NavLink to={'#'} onClick={()=>{
                                      }}>
                                        <i className="fa fa-edit" aria-hidden="true"></i>
                                      </NavLink>
                                    </div>  
                                  </li>                            
                                    )
                                  })} 

                              </ul>
                            </div>
                            }
                            <div className="col-md-12">
                            {isload && 
                                <div className="load-img">
                                  Uploading...
                                  <div className="loader"></div>
                                </div>
                            }
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 text-center mt-4 pub-btn-fixed">
                     
                        </div>
                      </div>        
                    </div>
                  </section>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="inputSlug">Remarks *</label>
                        <input
                          type="text"
                          className="form-control"
                          name="remarks"
                          value={removeCont}
                          readOnly={true}
                          onChange={handleChange}
                          
                        />
                      </div>
                                          
                    </div>

                                        {/* {  localStorage && localStorage.length > 0 && localStorage.videoOpt &&      */}
                                        <button className="btn btn-primary" type="button" onClick={() => handleSubmit(3)}>Save</button>
                    {/* }                   */}
                    {  localStorage && localStorage.length > 0 && isAccept &&     

                      <button className="btn btn-primary" type="button" onClick={() => handleSubmit(1)}>Accept</button>
                    }
                     {  localStorage && localStorage.length > 0 && isAccept &&     

                        <button className="btn btn-primary" type="button" onClick={() => handleSubmit(2)}>Reject</button>
                      }  
                  </form>
                </div>
              </div>
              }
              {isload &&
                <div className="col-md-12">
                    <div className="load-img">
                      Loading...
                      <div className="loader"></div>
                    </div>
                </div>
              }
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  </>
  );
};

export default EditStnVideo;
