import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import { NavLink, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import moment from 'moment';
import Pagination from 'react-bootstrap/Pagination';
import API_URL from './ApiData';
import DiffMatchPatch from 'diff-match-patch';

const StoryPreview = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageList, setPageList] = useState([1, 2, 3]);
    const [lt, setLt] = useState(5);
    const [lastPage, setLastPage] = useState(10);
    const { editId } = useParams();
    const [allStoryList, SetAllStoryData] = useState([]);

    const dmp = new DiffMatchPatch();

    const handleNextPage = () => {
        if (lastPage > currentPage) {
            setCurrentPage(currentPage + 1);
            let newArry = pageList;
            let newAddArray = pageList[pageList.length - 1] + 1;
            let newRemoveArray = pageList[0];
            newArry.shift(newRemoveArray);
            newArry.push(newAddArray);
            setPageList(newArry);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            let newArry = pageList;
            let newAddArray = pageList[pageList.length - 1];
            let newRemoveArray = pageList[0] - 1;
            newArry.unshift(newRemoveArray);
            newArry.pop(newAddArray);
            setPageList(newArry);
        }
    };

    const handleLastPage = () => {
        setCurrentPage(lt);
        let lastArrtty = [lt - 3, lt - 2, lt - 1];
        setPageList(lastArrtty);
    };

    const handleStartPage = () => {
        setCurrentPage(1);
        let lastArrtty = [1, 2, 3];
        setPageList(lastArrtty);
    };

    const handleSetPage = (page) => {
        setCurrentPage(page);
    };

    const fetchData = async (page) => {
        const allStoryAPI = API_URL.getStoryData + editId;

        axios
            .get(allStoryAPI)
            .then((res) => {
                const allStoryData =
                    res.data &&
                    res.data.length > 0 &&
                    res.data.map((item) => {
                        return {
                            content: item.content,
                            short_description: item.short_description,
                            summary: item.summary,
                            title: item.title,
                            user: item.user
                        };
                    });
                SetAllStoryData(allStoryData);
            });
    };
    const generateDiff = (oldText, newText) => {
      const diff = dmp.diff_main(oldText || '', newText || '');
      dmp.diff_cleanupSemantic(diff);
  
      // Convert the diff array into a single HTML string
      return diff.map((part) => {
          const color = part[0] === 1 ? 'green' : part[0] === -1 ? 'red' : 'black';
          const style = `background-color: ${color === 'black' ? 'transparent' : color}; color: ${color === 'black' ? 'black' : 'white'};`;
          return `<span style="${style}">${part[1]}</span>`;
      }).join('');
  };
    const generateDiffContent = (oldText, newText) => {
      const diff = dmp.diff_main(oldText || '', newText || '');
      dmp.diff_cleanupSemantic(diff);
  
      // Convert the diff array into a single HTML string
      return diff.map((part) => {
          const color = part[0] === 1 ? 'green' : part[0] === -1 ? 'red' : 'black';
          const style = `background-color: ${color === 'black' ? 'transparent' : color}; color: ${color === 'black' ? 'black' : 'black'};`;
          return `<span style="${style}">${part[1]}</span>`;
      }).join('');
  };

    useEffect(() => {
        if (localStorage && localStorage.langg && (localStorage.langg === 'hindi' || localStorage.langg === 'ce')) {
            fetchData(editId);
        }
    }, [editId]);

    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <link rel="icon" href="icon-new.ico" type="image/x-icon" />
                <link rel="shortcut icon" href="/theme/Ianstv/images/favicon.ico" type="image/x-icon" />
                <meta data-meta-title="Ianstv.in" name="keywords" content="Ianstv news, Ianstv updates, Ianstv celebrities, Hindi cinema, Indian film industry, Ianstv gossips, Ianstv rumors, Ianstv releases, Movie reviews, Celebrity interviews" />
                <meta data-meta-title="Ianstv.in" name="description" content="Stay up-to-date with the latest Ianstv news and updates. Get the hottest gossips, rumors, movie reviews, and exclusive celebrity interviews from the captivating world of Hindi cinema. Your one-stop destination for everything Ianstv." />
            </Helmet>
            <div className="wrapper">
                <Header />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-10">
                                    <h1 className="m-0">Stories Preview</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="col-lg-12">
                                <div className="card card-outline card-primary">
                                    <div className="card-body">
                                        <div className="container">
                                            <ul className="nav nav-tabs preview-tab">
                                                {allStoryList && allStoryList.length > 0 && allStoryList.map((tb, t) => {
                                                    return (
                                                        <li style={{ backgroundColor: t == allStoryList.length - 1 ? '#7a7a7a' : '' }} key={t}><a className={t == allStoryList.length - 1 ? 'active' : ''} data-toggle="tab" href={'#Version' + (t + 1)}>Version {t + 1}</a></li>
                                                    )
                                                })}
                                            </ul>

                                            <div className="tab-content">
                                                {allStoryList && allStoryList.length > 0 && allStoryList.map((conn, c) => {
                                                    const prevConn = allStoryList[c - 1] || {};
                                                    return (
                                                        <div id={'Version' + (c + 1)} className={c == allStoryList.length - 1 ? 'tab-pane fade in active show' : 'tab-pane fade in'}>
                                                            <form className="edit-form" action="#" method="post">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                            <div className="form-edit">Edited By | {conn.user} </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="exampleInputTitle">Title</label>
                                                                            <p dangerouslySetInnerHTML={{ __html: generateDiff(prevConn.title, conn.title)}}/>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="exampleInputShort">Short Description</label>
                                                                            <p data-short-desc="" dangerouslySetInnerHTML={{ __html: generateDiffContent(prevConn.short_description, conn.short_description)}}/>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="exampleFormControlTextarea2">Main Content</label>
                                                                            <p className="" dangerouslySetInnerHTML={{ __html: generateDiffContent(prevConn.content, conn.content) }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default StoryPreview;
