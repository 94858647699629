const BASE_URL = 'https://cms.iansnews.in/api/';
const API_URL={
  Login_URL : BASE_URL+'user/login/',
  Create_Story_URL : 'https://cms.iansnews.in/api/stories/create/',
  CREATE_NEWS : 'https://cms.iansnews.in/api/news/',
  CREATE_VIDEO : 'https://cms.iansnews.in/api/videos/',
  CREATE_PHOTO : 'https://cms.iansnews.in/api/photos/',
  getUserData : 'https://cms.iansnews.in/api/users/list/?group=',
  Story_URL : 'https://cms.iansnews.in/api/list-all-stories/?language=',
  getStoryData : 'https://cms.iansnews.in/api/version/',
  getStoryCount : 'https://cms.iansnews.in/api/news/count/?language=',
  createAndAssignStory : 'https://cms.iansnews.in/api/stories/create_and_assign_story/',
  getTagData : 'https://cms.iansnews.in/api/stories/tags/',
  fileUploadUrl : 'https://cms.iansnews.in/api/images/multiupload/',
  VIDEO_ADD : BASE_URL+'video-portal/',
  VIDEO_EDIT: BASE_URL+'video-portal-get/',
  VIDEO_UPDATE: BASE_URL+'video-portal/',
  ADD_JUST_IN: BASE_URL+'just-in/',
  JUST_LIST: BASE_URL+'just-in/',
  JUST_GET: BASE_URL+'just-in/',
  JUST_UPDATE : BASE_URL+'just-in/',
  GET_TAGS : BASE_URL+'stories/tags/',

}
export default API_URL  