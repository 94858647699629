import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import {Helmet} from "react-helmet";
import { useHistory, NavLink } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Multiselect from 'multiselect-react-dropdown'; // Adjust according to your Multiselect library
import API_URL from './ApiData'
import {useParams} from 'react-router-dom';
import copy from "copy-to-clipboard"
import { format, parseISO } from 'date-fns'
const PhotoEditStn = () => {
  const history = useHistory()
  const {editId} = useParams();
  const handleChange = event=>{
    const {name, value} =event.target;
    setInputData({ ...inputData, [name]: value });

  }
  const [readOn, setReadOn] = useState(false)
  const [conData, setValue] = useState('');
  const [inputData, setInputData] = useState({
    id: null,
    title: '',
    caption: '',
    images: [],
    status: "",
    language: '',
    created_by: "",
    accepted_by: "",
    remarks: "",
    created_at: ""
  })
  const formatDate = (timestamp)=>{
    const date = parseISO(timestamp)
    return format(date, 'MMMM dd, yyyy h:mm a')
}
  const fetchData = async () => {
    setIsload(true)
      axios(
         {method: "get",
          url: `${API_URL.CREATE_PHOTO}${editId}/`,
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Token ${localStorage.accessToken ? localStorage.accessToken : ''}`, // Ensure correct header
          },
        
        })
      .then((res) => {
          if(res && res.data){
            setInputData({
              title: res.data.title,
              caption: res.data.caption,
              status: res.data.status,
              language: res.data.language,
              created_by: res.data.created_by,
              accepted_by:res.data.accepted_by,
              remarks: res.data.remarks,
              images:res.data.images,
              created_at: res.data.created_at ? formatDate(res.data.created_at): '',
            })
            setValue(res.data.caption)
            if(res.data.images && res.data.images.length > 0){
              setImgAry(res.data.images)
            }
            setIsload(false)
            if(res.data.remarks){
              setRemoveCont(res.data.remarks)
            }
            if(res.data.created_by && localStorage && localStorage.length > 0 && localStorage.userName && res.data.created_by != localStorage.userName){
              setIsAccept(true)
             setIsload(false)
            }
          }
          
      });
     // setIsLoading(false)
  }

  // Usage

  const [isAccept, setIsAccept] = useState(false)
  const handleSubmit =  (type) => {
   // e.preventDefault();
   setIsload(true)
    if(!inputData.title){
      alert('Title can not be blank.');
      setIsload(false)
      return false;
    }else if(!inputData.caption){
      alert('caption can not be blank.');
      setIsload(false)
      return false;
    }

    const temp = imgArry.map(image => image.id);

    let tempdata = {
      id: inputData.id,
      title: inputData.title,
      caption: conData,
      image_ids: temp,
      status: 'on_hold',
      language: inputData.language,
      created_by: inputData.created_by,
      // accepted_by: inputData.accepted_by,
      remarks: removeCont,
      created_at: inputData.created_at,
    }
    let tempAccptdata = {
      id: inputData.id,
      title: inputData.title,
      caption: conData,
      image_ids: temp,
      status: 'accepted',
      language: inputData.language,
      created_by: inputData.created_by,
      accepted_by: localStorage && localStorage.length > 0 && localStorage.userName ? localStorage.userName : '',
      remarks: removeCont,
      created_at: inputData.created_at,
    }
    let tempRejectdata = {
      id: inputData.id,
      title: inputData.title,
      caption: conData,
      image_ids: temp,
      status: 'rejected',
      language: inputData.language,
      created_by: inputData.created_by,
      accepted_by: localStorage && localStorage.length > 0 && localStorage.userName ? localStorage.userName : '',
      remarks: removeCont,
      created_at: inputData.created_at,
    }
    axios({
      method: "put",
      url: `${API_URL.CREATE_PHOTO}${editId}/`,
      data: type && type == 1 ? tempAccptdata : type == 2 ? tempRejectdata : tempdata,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.accessToken ? localStorage.accessToken : ''}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        console.log('Success:', response.data);
        setIsload(false)
        if(type && type !=4){
          history.push('/all-photos-stn'); // Redirect to the home page on success

        }
      }
    })
  }
  const [isload, setIsload] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [imgModal, setImgModal] = useState(false);
  const [msg, setMsg]= useState('')
  const [imgArry, setImgAry] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleFileChange = (e) => {
    if(e && e.target && e.target.files[0]){
      if(e.target.files[0].type != "image/jpeg" && e.target.files[0].type != "image/jpg"){
        alert(`${e.target.files[0].type}  not be allowed.`)
        e.target.value = null;

      }
    }
    const files = e.target.files;
    setSelectedFiles([...files]); // Use the spread operator to create a new array
    
  };
  const uploadImage = (e) => {
    e.preventDefault();
    const formData = new FormData();
    // Loop through selected files and upload them one by one
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append('image', selectedFiles[i]);
      }
      console.log(formData, 'formDataa')

        setIsLoading(true)
        axios({
          method: "post",
          url: `${API_URL.CREATE_PHOTO}upload-photos/`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${localStorage.accessToken ? localStorage.accessToken :''}`,
            

          },
        
        })
          .then((res) => {
            if(res && res.data && res.data && res.data.uploaded_images && res.data.uploaded_images.length > 0){
                const newImage =
                        res.data &&
                        res.data.uploaded_images &&
                        res.data.uploaded_images.length > 0 &&
                        res.data.uploaded_images.map((item) => {
                        return {
                          id:item.id ? item.id : "",
                          image: item.image_url ? item.image_url : "",
                        }})
              // Add the new image to the image array
              if(imgArry && imgArry.length > 0){
                // let bewwww = imgArry.push(newImage);
                // setImgAry((imgArry) => [newImage, ...imgArry]);
                const mergedArray = imgArry.concat(newImage);
                setImgAry(mergedArray)
              }else{
                setImgAry(newImage);
              }
              handleSubmit(4)
              setIsLoading(false)
              setImgModal(false)
              console.log(imgArry, 'mukesh')
            }
          })
          .catch((error) => {
            setIsLoading(false)
            console.error("Error uploading image: ", error);
          });
      
      // Clear the form input fields and selected files

      setSelectedFiles([]);
      

  };
  const uploadcImage = (e) => {
    e.preventDefault();
    const formData = new FormData();
    // Loop through selected files and upload them one by one
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append('image', selectedFiles[i]);
      }
      console.log(formData, 'formDataa')

        setIsLoading(true)
        axios({
          method: "post",
          url: `${API_URL.CREATE_PHOTO}upload-photo/`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${localStorage.accessToken ? localStorage.accessToken :''}`,
            

          },
        
        })
          .then((res) => {
            if(res && res.data && res.data && res.data.uploaded_images && res.data.uploaded_images.length > 0){
                const newImage =
                        res.data &&
                        res.data.uploaded_images &&
                        res.data.uploaded_images.length > 0 &&
                        res.data.uploaded_images.map((item) => {
                        return {
                          id:item.id ? item.id : "",
                          image: item.image_url ? item.image_url : "",
                        }})
              // Add the new image to the image array
              if(imgArry && imgArry.length > 0){
                // let bewwww = imgArry.push(newImage);
                // setImgAry((imgArry) => [newImage, ...imgArry]);
                const mergedArray = imgArry.concat(newImage);
                setImgAry(mergedArray)
              }else{
                setImgAry(newImage);
              }
              handleSubmit(3)
              setIsLoading(false)
              setImgModal(false)
              console.log(imgArry, 'mukesh')
            }
          })
          .catch((error) => {
            setIsLoading(false)
            console.error("Error uploading image: ", error);
          });
      
      // Clear the form input fields and selected files

      setSelectedFiles([]);
      

  };
  const [removeCont, setRemoveCont] = useState('')
  const removeImg = (id)=>{
   // alert(id)
    const newImgArry = imgArry.filter(item => item.id !== id)
    if(!removeCont){
      setRemoveCont('Deleted id -'+id)
    }else{
      setRemoveCont(removeCont + ', '+id)
    }
    
    setImgAry(newImgArry)
  }
  useEffect(() => {
    // Fetch tags
    // if(localStorage && localStorage.length > 0 && (localStorage.videoOpt || localStorage.editor)){
    //   setReadOn(false)
    // }
    fetchData()
 
  }, [editId]);
  return (
    <>
<Helmet>
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <link rel="icon" href="icon-new.ico" type="image/x-icon" />
  <link rel="shortcut icon" href="/theme/Ianstv/images/favicon.ico" type="image/x-icon" />
  <meta data-meta-title="Ianstv.in" name="keywords" content="Ianstv news, Ianstv updates, Ianstv celebrities, Hindi cinema, Indian film industry, Ianstv gossips, Ianstv rumors, Ianstv releases, Movie reviews, Celebrity interviews" />
  <meta data-meta-title="Ianstv.in" name="caption" content="Stay up-to-date with the latest Ianstv news and updates. Get the hottest gossips, rumors, movie reviews, and exclusive celebrity interviews from the captivating world of Hindi cinema. Your one-stop destination for everything Ianstv." />
</Helmet>
  <div className="wrapper">
  <Header />
  <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-10">
                <h1 className="m-0">Edit Photos</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="col-lg-12">
            {!isload &&
              <div className="card card-outline card-primary">
                <div className="card-body">
                  <form>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="inputHeading">Title: *</label>
                        <input
                          type="text"
                          name="title"
                          className="form-control"
                          id="inputHeading"
                          value={inputData.title}
                          onChange={handleChange}
                          readOnly ={readOn}
                        />
                      </div>
                    </div>

                    <div className="d-md-flex">
                        <div className="w-100">
                            <div className="form-group">
                                <label>Main Content *</label>
                                <div className='video-editor'>
                                  <ReactQuill className="form-control form-control-user editShortDesc" 
                                  theme="snow" 
                                  type="text"
                                  name="content" 
                                  value={conData} 
                                  onChange={setValue}
                                  readOnly ={readOn}
                                  />
                                </div>                                
                            </div>
                        </div>
                    </div>
  
                                                                          
                <section className="content">
                    <div className="container-fluid">
                      <div className="col-lg-12">
                        <div className="card card-outline card-primary">
                          <div className="card-body">
                    {  !isload &&                  
                            <div className="col-md-12">																	
                              <div className="accordion" id="morePhoto">
                                <button className="addimg-btn btn btn-primary" type="button" onClick={()=>{
                                    setImgModal(!imgModal)
                                  }}>
                                + Add Images
                                </button>
                              {isLoading     && 
                                <div className="load-img">
                                  Uploading...
                                  <div className="loader"></div>
                                </div>  
                              }	
                                <div className="accordion" id="morePhoto">
                          {imgModal &&
                            <div>
                              <div className="card card-body">
                              <form encType="multipart/form-data" className="border p-2 mb-2 rounded">
                                    <div className="form-row">
                                    <div className="form-group col-md-2">
                                      <label htmlFor="files">Photos</label>
                                      <input
                                        type="file"
                                        onChange={handleFileChange}
                                        name="image"
                                        multiple
                                        required
                                        accept="image/jpeg, image/jpg"
                                      />
                                    </div>
                                  </div>
                                  
                                  <div className="form-row">
                                    <button className="btn btn-success" onClick={uploadImage}>
                                      Add
                                    </button>
                                    <button className="btn btn-secondary" type="button">
                                      Cancel
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>}  
                            </div>
                              </div>
                              
                              <ul className="pending-pic edit-event-pic stn-photo-edit">
                              {imgArry && imgArry.length > 0 && imgArry.map((imgg, im)=>{
                             
                               let imggg =imgg.image ?  imgg.image.replace("/images/", "/thumbnails_watermark/"):''
                                    return(
                                  <li key={im}>
                                    <img src={imggg}/>
                                    <div className="editpho-icon">
                                      <NavLink to={'#'} onClick={() => removeImg(imgg.id)}>
                                        <i className="fa fa-trash" aria-hidden="true"></i>
                                      </NavLink>
                                      <NavLink to={'#'} onClick={()=>{
                                              
                                      }}>
                                        <i className="fa fa-edit" aria-hidden="true"></i>
                                      </NavLink>
                                    </div>  
                                  </li>                            
                                    )})} 

                              </ul>
                            </div>
                            }
                            <div className="col-md-12">
                            {isload && 
                                <div className="load-img">
                                  Uploading...
                                  <div className="loader"></div>
                                </div>
                            }
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 text-center mt-4 pub-btn-fixed">
                        </div>
                      </div>        
                    </div>
                  </section>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="inputSlug">Remarks *</label>
                        <input
                          type="text"
                          className="form-control"
                          name="remarks"
                          value={removeCont}
                          readOnly={true}
                          onChange={handleChange}
                          
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="inputLanguage">Language *</label>
                          <select
                            className="form-control"
                            name="language"
                            value={inputData.language}
                            onChange={handleChange}
                          >
                            <option value="">-- Select Language --</option>
                            <option value="en">English</option>
                            <option value="hi">Hindi</option>
                          </select>
                      </div>          
                    </div>
                    {/* {  localStorage && localStorage.length > 0 && localStorage.videoOpt &&      */}
                      <button className="btn btn-primary" type="button" onClick={() => handleSubmit(3)}>Save</button>
                    {/* }                   */}
                    {  localStorage && localStorage.length > 0 && isAccept &&     

                      <button className="btn btn-primary" type="button" onClick={() => handleSubmit(1)}>Accept</button>
                    }
                     {  localStorage && localStorage.length > 0 && isAccept &&     

                        <button className="btn btn-primary" type="button" onClick={() => handleSubmit(2)}>Reject</button>
                      }                   
                  </form>
                </div>
              </div>
            }
              {isload &&
                <div className="col-md-12">
                    <div className="load-img">
                      Loading...
                      <div className="loader"></div>
                    </div>
                </div>
              }
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  </>
  );
};

export default PhotoEditStn;
